import { Component, OnInit, HostListener } from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { EtbInternalService } from 'src/app/services/etb-internal.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { OtpService } from 'src/app/services/otp.service';

@Component({
  selector: 'app-port-agent-etb-detail-update',
  templateUrl: './port-agent-etb-detail-update.component.html',
  styleUrls: ['./port-agent-etb-detail-update.component.scss'],
})
export class PortAgentEtbDetailUpdateComponent implements OnInit {
  settings: any = {
    isDisable: false,
    showHover: true,
    showCursorNotAllowed: false,
    isTitleClickable: true,
  };
  openModel: boolean = false;
  snlModalSettings = { showCloseIcon: true, backdropClick: false };
  isSessionExpired = false;
  isSessionInactive = false;
  isSessionOngoing = false;
  etbList: any = [];
  isSessionInactivityModalCloseButtonClicked = false;
  berthDetails: any;
  etbClassName: boolean = false;
  isSameBerthId: any;

  // From variable
  hstep = 1;
  mstep = 15;
  portForm: FormGroup;
  currentTimerType: any;
  hasErrors: boolean;
  hasSameErrors: boolean;
  hasErrorsApiLevel: boolean;
  hasPendingStatus: boolean;
  activeApiRequests: any;
  etaErrorMessage: any;
  etdErrorMessage: any;
  tokenLink: string;
  portFormArray: any = [];
  selectBerthIndex: any;
  isDisableBtn:boolean = true;
  isMainTab: any;
  isReload: boolean = false;
  formSubmitted = false;
  private sessionCheckInterval: any;
  private resetDebounceTimeout: any;
  private readonly SESSION_DURATION = 20 * 60 * 1000; // 20 minutes in ms
  private readonly WARNING_THRESHOLD = 2 * 60 * 1000;  // 2 minutes in ms

  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private etbInternalService: EtbInternalService,
    private navigationService: NavigationService,
    public otpService: OtpService,
    private router:Router
  ) {
    this.navigationService.setPageTitle('ETBs Updates');
    this.hstep = 1;
  }

  ngOnInit(): void {
    this.isMainTab = sessionStorage.getItem('isMainTab');
    console.log("isMainTab value::",this.isMainTab);
    this.route.params.subscribe(paramsId => {
      const randomeNo = paramsId.id;
      this.tokenLink = paramsId.id;

      const performanceEntries = performance.getEntriesByType('navigation') as PerformanceNavigationTiming[];
      this.isReload = performanceEntries[0]?.type === 'reload';

      this.etbInternalService.getPortAgtEtbData(randomeNo).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            console.log("this.isReload::",this.isReload);
            this.handlePageLoad(this.isReload, res);
          }
        }, error: err => {
          this.router.navigateByUrl('/error');
        }
      })
    });
  }

  private handlePageLoad(isReload: boolean, res: any): void {
    if (isReload) {
      this.handleReload(res);
    } else {
      this.handleNormalLoad(res);
    }
  }
  
  private handleReload(res: any): void {
    console.log('Page reloaded!');
    if (this.isMainTab === 'true') {
      console.log("It's a reload and the main tab, show data");
      this.getPortAgtEtbData(res);
    } else {
      console.log("It's a reload but not the main tab, show active session message");
      this.isSessionOngoing = true;
    }
  }
  
  private handleNormalLoad(res: any): void {
    console.log('Normal page load.');
    if (this.isMainTab) {
      console.log("It's a normal load and the main tab, show data");
      this.getPortAgtEtbData(res);
    } else {
      console.log("It's a normal load but not the main tab, show active session message");
      this.isSessionOngoing = true;
    }
  }

  getPortAgtEtbData(res:any) {
    console.log("Inside isSessionOngoing else::",this.isSessionOngoing);
    this.startSession();
    console.log("Session started");
    this.sessionCheckInterval = setInterval(() => {
        if (!this.isSessionValid()) {
            this.isSessionExpired = true;
            this.openModel = false;
        }
    }, 60000); // Check every 1 minute
    this.etbList = res.data.etbList;
    // intilizeportForm
    this.portFormArray = this.fb.array([]); // Initialize an empty FormArray
    setTimeout(() => {
      for (let row of this.etbList) {
        row.isExpanded = false;
        this.initializeForm(row);
      }
    }, 100)
  }

  getArBerths(index: number): FormArray {
    if (index < 0 || index >= this.portFormArray.length) {
      throw new Error('Index out of bounds');
    }
    return (this.portFormArray.at(index) as FormGroup).get('arBerths') as FormArray;
  }

  getCargoes(berth: any): FormArray {
    return berth.get('arCargoes') as FormArray;
  }
 

  checkAndDisableActualDates(): void {
    this.portFormArray.controls.forEach((portFormGroup: any, portIndex: number) => {
      // Get arBerths array for the current portFormGroup
      const arBerths = this.getArBerths(portIndex);

      // Iterate through the arBerths FormArray
      arBerths.controls.forEach((berthGroup: any) => {
        const actualDateArival = berthGroup.get('actualDateArival'); // ATA
        const estimatedDateArival = berthGroup.get('estimatedDateArival'); // ETA

        const actualDateDeparture = berthGroup.get('actualDateDeparture'); // ATD
        const estimatedDateDeparture = berthGroup.get('estimatedDateDeparture'); // ETD

        // For ETA/ATA 
        if (this.isActualAndEstimatedArrivalPresent(actualDateArival, estimatedDateArival) || (actualDateArival?.value && !estimatedDateArival?.value)) {
          berthGroup.get('actualTimeArivalDisable')?.setValue(this.fromLocalISOFormat(actualDateArival.value));
          berthGroup.get('actualTimeArivalDisplay')?.setValue(this.fromLocalISOFormat(actualDateArival.value));
          berthGroup.get('actualDateArivalDisplay')?.setValue(this.fromLocalISOFormat(actualDateArival.value));
        } else if (!actualDateArival?.value && estimatedDateArival?.value) {
          berthGroup.get('actualTimeArivalDisplay')?.setValue(this.fromLocalISOFormat(estimatedDateArival.value));
          berthGroup.get('actualDateArivalDisplay')?.setValue(this.fromLocalISOFormat(estimatedDateArival.value));
          berthGroup.get('actualDateArivalDisplay')?.enable();
        } else {
          berthGroup.get('actualDateArivalDisplay')?.enable();
        }

        // For ETD/ATD 
        if ((actualDateDeparture?.value && estimatedDateDeparture?.value) || (actualDateDeparture?.value && !estimatedDateDeparture?.value)) {
          berthGroup.get('actualTimeDepartureDisable')?.setValue(this.fromLocalISOFormat(actualDateDeparture.value));
          berthGroup.get('actualTimeDepartureDisplay')?.setValue(this.fromLocalISOFormat(actualDateDeparture.value));
          berthGroup.get('actualDateDepartureDisplay')?.setValue(this.fromLocalISOFormat(actualDateDeparture.value));
        } else if (!actualDateDeparture?.value && estimatedDateDeparture?.value) {
          berthGroup.get('actualTimeDepartureDisplay')?.setValue(this.fromLocalISOFormat(estimatedDateDeparture.value));
          berthGroup.get('actualDateDepartureDisplay')?.setValue(this.fromLocalISOFormat(estimatedDateDeparture.value));
          berthGroup.get('actualDateDepartureDisplay')?.enable();
        } else {
          berthGroup.get('actualDateDepartureDisplay')?.enable();
        }
      });
    });
  }

  isActualAndEstimatedArrivalPresent(actualDateArival:any, estimatedDateArival:any ): boolean {
    return actualDateArival?.value && estimatedDateArival?.value;
  }


  fromLocalISOFormat(isoString: any) {
    const date = new Date(isoString);

    const year = date.getUTCFullYear();
    const month = date.getUTCMonth(); // Months are zero-indexed
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const milliseconds = date.getUTCMilliseconds();

    return new Date(year, month, day, hours, minutes, seconds, milliseconds);
  }

  initializeForm(berthDetails: any) {
    this.portForm = this.fb.group({
      portNo: [berthDetails.portNo],
      portName: [berthDetails.portName],
      seqNo: [berthDetails.seqNo],
      vesVoy: [berthDetails.vesVoy],
      arBerths: this.fb.array([]),
      _id: [berthDetails._id],
      dtETA: [berthDetails.dtETA],
      dtETD: [berthDetails.dtETD]
    });
    // Populate arBerths array
    const arBerthsFormArray = this.portForm.get('arBerths') as FormArray;
    berthDetails.arBerths.forEach((berth: any) => {
      arBerthsFormArray.push(this.createBerthFormGroup(berth));
    });
    this.portFormArray.push(this.portForm);
    this.checkAndDisableActualDates();
    return this.portForm; // Return the created form group
  }

  createBerthFormGroup(berth: any): FormGroup {
    const defaultTime = moment().hours(12).minutes(0).seconds(0).toDate();
    return this.fb.group({
      berthSeqNo: [berth.berthSeqNo],
      berthName: [berth.berthName],
      estimatedDateArival: [berth.estimatedDateArival],
      estimatedDateDeparture: [berth.estimatedDateDeparture],
      actualDateArival: [berth.actualDateArival],
      actualDateDeparture: [berth.actualDateDeparture],
      actualTimeArival: [this.fromLocalISOFormat(berth.estimatedDateArival) || defaultTime],
      actualTimeDeparture: [this.fromLocalISOFormat(berth.estimatedDateDeparture) || defaultTime],
      _id: [berth._id],
      actualTimeArivalDisplay: [null],
      actualTimeDepartureDisplay: [null],
      actualTimeArivalDisable: [null],
      actualTimeDepartureDisable: [null],
      actualDateArivalDisplay: [null],
      actualDateDepartureDisplay: [null],
      etaActualMergedDateTime: [null],
      etdDepartureMergedDateTime: [null],
      etaActualErrorMessage: [null],
      type: [null],
      etdDepartureErrorMessage: [null],
      vslLevelErrorMessage: [null],
      arCargoes: this.fb.array(
        berth.arCargoes.map((cargo: any) => this.fb.group({ cargo: [cargo.cargo], func: [cargo.func] }))
      )
    });

  }


  currentTimerIndex: any
  berthIndex: any;
  displayPicker(vesselIndex: any, type: any, berthIndex: any) {
    this.selectBerthIndex = berthIndex;
    this.currentTimerIndex = vesselIndex;
    this.currentTimerType = type;
    this.berthIndex = berthIndex;
  }



  applyTimeCustom(index: number, type: any, berthIndex: any) {
    this.selectBerthIndex = berthIndex;
    let timeArival = this.getActualTimeArival(index, berthIndex);
    let timeDeparture = this.getActualTimeDeparture(index, berthIndex);

    // Set actualTimeArival to actualTimeArivalDisplay
    if (type == 'eta') {
      this.getArBerths(index)?.at(berthIndex)?.get('actualTimeArivalDisplay')?.setValue(timeArival);
    }

    // Set actualTimeDeparture to actualTimeDepartureDisplay
    if (type == 'etd') {
      this.getArBerths(index)?.at(berthIndex)?.get('actualTimeDepartureDisplay')?.setValue(timeDeparture);
    }
    this.displayPicker(index, 'close', berthIndex);
    this.handleErrorMessage(index, type, berthIndex);
  }

  getActualTimeArival(index: number, berthIndex: number): Date | null {
    return this.getArBerths(index)?.at(berthIndex)?.get('actualTimeArival')?.value ?? null;
  }

  getActualTimeDeparture(index: number, berthIndex: number): Date | null {
    return this.getArBerths(index)?.at(berthIndex)?.get('actualTimeDeparture')?.value ?? null;
  }

  mergeDateAndTime(date: any, time: any): any {
    if (!date || !time) {
      return null; // return current date-time if either date or time is null
    }
    const mergedDate = moment(date).set({
      hour: moment(time).hours(),
      minute: moment(time).minutes(),
      second: moment(time).seconds()
    });
    return mergedDate.toDate();
  }

  onSubmit(): void {
    this.buttonSubmitCheck();
      if (!this.hasErrors && !this.hasErrorsApiLevel && !this.hasSameErrors) {
        const cleanedPayload = this.cleanPayload(this.portFormArray.value);
        this.updateEtbDetailsApi(cleanedPayload);
      }
  }
  

  cleanPayload(portFormArrayValue: any[]) {
    return portFormArrayValue.map((payload: any) => ({
      portNo: payload.portNo,
      seqNo: payload.seqNo,
      vesVoy: payload.vesVoy,
      arBerths: payload.arBerths.map((berth: any) => ({
        berthSeqNo: berth.berthSeqNo,
        berthName: berth.berthName,
        etaDateTime: berth.actualDateArivalDisplay && berth.actualTimeArivalDisplay 
        ? this.toLocalISOFormat(new Date(berth.actualDateArivalDisplay), new Date(berth.actualTimeArivalDisplay)) 
        : null,
        etdDateTime: berth.actualDateDepartureDisplay && berth.actualTimeDepartureDisplay 
          ? this.toLocalISOFormat(new Date(berth.actualDateDepartureDisplay), new Date(berth.actualTimeDepartureDisplay)) 
          : null
      }))
    }));
  }
  

  toLocalISOFormat(date: Date, time: any): string {
    const pad = (num: number) => num.toString().padStart(2, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-indexed
    const day = pad(date.getDate());
    const hours = pad(time.getHours());
    const minutes = pad(time.getMinutes());
    const seconds = pad(time.getSeconds());
    const milliseconds = (time.getMilliseconds() / 1000).toFixed(3).slice(2, 5); // Milliseconds in three digits

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }

  updateEtbDetailsApi(cleanedPayload: any) {
    let payload = {
      vesPortBerths : cleanedPayload,
      token: this.tokenLink
    }
    this.hasErrors = true;
    this.isDisableBtn = true;
    this.etbInternalService.updateEtbByPortAgt(payload).subscribe({
      next: (res: any) => {
        this.hasErrors = false;
        this.isDisableBtn = false;
        if (res.status == 200) {
          this.formSubmitted = true;
          this.closeSession();
          this.router.navigateByUrl('/thank_you');
        }
      }, error: err => {
        this.isDisableBtn = false;
        this.hasErrors = false;
        if (err?.error?.error.status === 400) {
          this.navigationService.showError(err?.error?.error?.description, '400');
        } else {
          this.navigationService.showError(err?.error?.error?.description, 'error');
        }
      }
    })
  }

  handleErrorMessage(currentIndex: any, type: any, berthIndex: any) {
    this.selectBerthIndex = berthIndex;
    const currentBerth = this.getArBerths(currentIndex).at(berthIndex);
    if ((currentBerth.value.actualDateArivalDisplay && currentBerth.value.actualTimeArivalDisplay && type == 'eta') || (currentBerth.value.actualDateDepartureDisplay && currentBerth.value.actualTimeDepartureDisplay && type == 'etd')) {
      this.hasErrors = false;
      this.hasSameErrors = false;
      this.getArBerths(currentIndex)?.at(berthIndex)?.get('type')?.setValue(type);

      this.portFormArray.controls.forEach((portFormGroup: any, portIndex: number) => {
        // Get arBerths array for the current portFormGroup
        const arBerths = this.getArBerths(portIndex);
        if (currentIndex == portIndex) {
          arBerths.controls.forEach((berthGroup: any, index: any) => {
            const berthSeqNo = berthGroup.get('berthSeqNo')?.value;
            const actualDateArivalDisplay = berthGroup.get('actualDateArivalDisplay')?.value;
            const actualTimeArivalDisplay = berthGroup.get('actualTimeArivalDisplay')?.value;
            const actualDateDepartureDisplay = berthGroup.get('actualDateDepartureDisplay')?.value;
            const actualTimeDepartureDisplay = berthGroup.get('actualTimeDepartureDisplay')?.value;
            const mergedArrival = this.mergeDateAndTime(actualDateArivalDisplay, actualTimeArivalDisplay);
            const mergedDeparture = this.mergeDateAndTime(actualDateDepartureDisplay, actualTimeDepartureDisplay);
            berthGroup.patchValue({
              etaActualMergedDateTime: mergedArrival,
              etdDepartureMergedDateTime: mergedDeparture
            });


            // Initialize error messages
            this.etaErrorMessage = null;
            this.etdErrorMessage = null;

            const actualDateArival = berthGroup.get('actualDateArival')?.value;
            const actualDateDeparture = berthGroup.get('actualDateDeparture')?.value;
            if (!(actualDateArival && actualDateDeparture)) {
              this.checkApiErrorMessage(berthSeqNo, berthGroup, actualDateArivalDisplay, actualTimeArivalDisplay, actualDateDepartureDisplay, actualTimeDepartureDisplay, portFormGroup);
            }

            // Set error messages
            berthGroup.patchValue({
              etaActualErrorMessage: this.etaErrorMessage,
              etdDepartureErrorMessage: this.etdErrorMessage
            });
          });
        }
      });

      this.checkEmptyEtaEtd(currentIndex, berthIndex); // Check empty date and time validaton
      this.checkSameBerthDateLieValidation(currentIndex, berthIndex); // Check same berth date line for different vessel
     // Remove Sequence this.checkPreviousDateTime(currentIndex, berthIndex); // Overlapping
    // Remove Sequence  this.checkFutureDateTime(currentIndex, berthIndex); // Overlapping
      this.checkOverlappingSequence(currentIndex, berthIndex); // Overlapping without sequence
      this.checkEtaEtdValidation(currentIndex, berthIndex); // Same berth eta/etd date/time
      this.checkVesselPortWiseValidation(currentIndex, berthIndex); // Vessel port date/time
      this.checkSameBerthEqualValidation(currentIndex, berthIndex); // Same berth confilict date/time
      this.checkAgainstValue(currentIndex, berthIndex); // Another berth confilict date/time
      this.checkSameBerthValidationDisplay(currentIndex, type, berthIndex);
      setTimeout(() => {
        const allBerth = this.getArBerths(currentIndex);
        this.checkDateOrder(allBerth.value, currentIndex);
      }, 100)
    }
  }

  checkSameBerthValidationDisplay(currentIndex: any, type: any, berthIndex: any) {
    const currentBerth = this.getArBerths(currentIndex).at(berthIndex);
    let etaErrMsgValue = currentBerth.get('etaActualErrorMessage')?.value;
    let etdErrMsgValue = currentBerth.get('etdDepartureErrorMessage')?.value;
    if (type == 'eta' && etdErrMsgValue && etaErrMsgValue) {
      currentBerth.get('etaActualErrorMessage')?.setValue(etaErrMsgValue);
      currentBerth.get('etdDepartureErrorMessage')?.setValue('');
    } else if (type == 'etd' && etdErrMsgValue && etaErrMsgValue) {
      currentBerth.get('etaActualErrorMessage')?.setValue('');
      currentBerth.get('etdDepartureErrorMessage')?.setValue(etdErrMsgValue);
    }
  }

  checkSameBerthEqualValidation(currentIndex: any, berthIndex: any) {
    const currentBerth = this.getArBerths(currentIndex).at(berthIndex);
    const mergedArrival = currentBerth?.get('etaActualMergedDateTime')?.value;
    const mergedDeparture = currentBerth?.get('etdDepartureMergedDateTime')?.value;
    const etaEtdtype = currentBerth?.get('type')?.value;
    if (mergedArrival && mergedDeparture && mergedArrival.getTime() === mergedDeparture.getTime()) {
      this.hasSameErrors = true;
      if (this.hasErrors) {
        currentBerth.get('etaActualErrorMessage')?.setValue('');
        currentBerth.get('etdDepartureErrorMessage')?.setValue('');
      }
      if (etaEtdtype == 'eta') {
        this.etaErrorMessage = 'This date/time conflicts with another field on this form. Please check and confirm the correct date/time.';
        currentBerth.get('etaActualErrorMessage')?.setValue(this.etaErrorMessage);
      } else {
        this.etdErrorMessage = 'This date/time conflicts with another field on this form. Please check and confirm the correct date/time.';
        currentBerth.get('etdDepartureErrorMessage')?.setValue(this.etdErrorMessage);
      }
    }
  }

  checkVesselPortWiseValidation(currentIndex: any, berthIndex: any) {
    const currentPortForm = this.portFormArray.at(currentIndex).value;
    const currentBerth = this.getArBerths(currentIndex).at(berthIndex);
    const actualDateArivalDisplay = currentBerth?.get('actualDateArivalDisplay')?.value;
    const actualTimeArivalDisplay = currentBerth?.get('actualTimeArivalDisplay')?.value;
    const actualDateDepartureDisplay = currentBerth?.get('actualDateDepartureDisplay')?.value;
    const actualTimeDepartureDisplay = currentBerth?.get('actualTimeDepartureDisplay')?.value;
    let etaDate = this.toLocalISOFormat(new Date(actualDateArivalDisplay), new Date(actualTimeArivalDisplay));
    let etdDate = this.toLocalISOFormat(new Date(actualDateDepartureDisplay), new Date(actualTimeDepartureDisplay));
    if (actualDateArivalDisplay && actualTimeArivalDisplay && new Date(etaDate) < new Date(currentPortForm.dtETA)) {
      this.hasSameErrors = true;
      this.etaErrorMessage = 'This date/time should not be before the vessel’s port arrival date. Please check and confirm the correct date/time.';
      currentBerth.get('etaActualErrorMessage')?.setValue(this.etaErrorMessage);
    } else if (actualDateArivalDisplay && actualTimeArivalDisplay && new Date(etaDate) > new Date(currentPortForm.dtETD)) {
      this.hasSameErrors = true;
      this.etaErrorMessage = 'This date/time should not be after the vessel’s port departure date. Please check and confirm the correct date/time.';
      currentBerth.get('etaActualErrorMessage')?.setValue(this.etaErrorMessage);
    }
    if (actualDateDepartureDisplay && actualTimeDepartureDisplay && new Date(etdDate) > new Date(currentPortForm.dtETD)) {
      this.hasSameErrors = true;
      this.etdErrorMessage = 'This date/time should not be after the vessel’s port departure date. Please check and confirm the correct date/time.';
      currentBerth.get('etdDepartureErrorMessage')?.setValue(this.etdErrorMessage);
    } else if (actualDateDepartureDisplay && actualTimeDepartureDisplay && new Date(etdDate) < new Date(currentPortForm.dtETA)) {
      this.hasSameErrors = true;
      this.etdErrorMessage = 'This date/time should not be before the vessel’s port arrival date. Please check and confirm the correct date/time.';
      currentBerth.get('etdDepartureErrorMessage')?.setValue(this.etdErrorMessage);
    }
  }


  checkDateOrder(array: any, currentIndex: any) {
    let previousEtaDate: any = null;
    let previousEtdDate: any = null;
    if (!this.hasErrors && !this.hasSameErrors) {
      array.forEach((item: any, berthIndex: any) => {
        const currentEtaDate = item.etaActualMergedDateTime ? new Date(item.etaActualMergedDateTime) : null;
        const currentEtdDate = item.etdDepartureMergedDateTime ? new Date(item.etdDepartureMergedDateTime) : null;

        const actualDateArival = item.actualDateArival;
         const actualDateDeparture = item.actualDateDeparture;
         if(!(actualDateArival && actualDateDeparture)){
        this.checkEmptyEtaEtdForAll(currentIndex); // Check empty date and time validaton
        this.checkSameBerthDateLieValidation(currentIndex, berthIndex); // Check same berth date line for different vessel
      // Remove Sequence  this.checkOverlapValidationOrder(previousEtaDate, previousEtdDate, currentEtaDate, currentEtdDate, currentIndex, berthIndex);
        this.checkOverlappingSequence(currentIndex, berthIndex); // Overlapping without sequence
        this.checkEtaEtdValidation(currentIndex, berthIndex); // Same berth eta/etd date/time
        this.checkVesselPortWiseValidation(currentIndex, berthIndex); // Vessel port date/time
        this.checkSameBerthEqualValidation(currentIndex, berthIndex); // Same berth confilict date/time
        this.checkMatchDateTime(previousEtaDate, previousEtdDate, currentEtaDate, currentEtdDate, currentIndex, berthIndex, actualDateArival, actualDateDeparture); // Another berth confilict date/time
      }
        previousEtaDate = currentEtaDate;
        previousEtdDate = currentEtdDate;
      });
    }
  }

  checkOverlapValidationOrder(previousEtaDate: any, previousEtdDate: any, currentEtaDate: any, currentEtdDate: any, currentIndex: any, berthIndex: any) {
    let errorMessage = 'This date/time overlaps with another berth’s stay. Please check and confirm the correct date/time.';
    const currentBerth = this.getArBerths(currentIndex).at(berthIndex);
    if (previousEtaDate && currentEtaDate && new Date(currentEtaDate) < new Date(previousEtaDate)) {
      currentBerth.get('etaActualErrorMessage')?.setValue(this.etaErrorMessage);
      this.hasErrors = true;
    }

    if (previousEtaDate && currentEtaDate && new Date(currentEtaDate) < new Date(previousEtdDate)) {
      currentBerth.get('etaActualErrorMessage')?.setValue(this.etaErrorMessage);
      this.hasErrors = true;
    }

    if (previousEtdDate && currentEtdDate && new Date(currentEtdDate) < new Date(previousEtdDate)) {
      currentBerth.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }
    if (previousEtdDate && currentEtdDate && new Date(currentEtdDate) < new Date(previousEtaDate)) {
      currentBerth.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }
  }

  checkMatchDateTime(previousEtaDate: any, previousEtdDate: any, currentEtaDate: any, currentEtdDate: any, currentIndex: any, berthIndex: any, actualDateArival:any, actualDateDeparture:any) {
    let errorMessage = 'This date/time conflicts with another field on this form. Please check and confirm the correct date/time.';
    if (!actualDateArival && previousEtaDate && currentEtaDate && currentEtaDate.getTime() == previousEtaDate.getTime()) {
      this.getArBerths(currentIndex)?.at(berthIndex)?.get('etaActualErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }

    if (!actualDateArival && previousEtdDate && currentEtaDate && currentEtaDate.getTime() == previousEtdDate.getTime()) {
      this.getArBerths(currentIndex)?.at(berthIndex)?.get('etaActualErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }

    if (!actualDateDeparture && previousEtdDate && currentEtdDate && currentEtdDate.getTime() == previousEtdDate.getTime()) {
      this.getArBerths(currentIndex)?.at(berthIndex)?.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }

    if (!actualDateDeparture && previousEtaDate && currentEtdDate && currentEtdDate.getTime() == previousEtaDate.getTime()) {
      this.getArBerths(currentIndex)?.at(berthIndex)?.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }
  }

  checkFutureDateTime(currentIndex: any, berthIndex: any) {
    const currentBerth = this.getArBerths(currentIndex).at(berthIndex);
    const currentEtaActualMergedDateTime = currentBerth?.get('etaActualMergedDateTime')?.value;
    const currentEtdDepartureMergedDateTime = currentBerth?.get('etdDepartureMergedDateTime')?.value;
    const allBerth = this.getArBerths(currentIndex);
    for (let i = 0; i < allBerth.value.length; i++) {
      if (i > berthIndex) {
        const berth = this.getArBerths(currentIndex).at(i);
        const etaActualMergedDateTime = berth?.get('etaActualMergedDateTime')?.value;
        const etdDepartureMergedDateTime = berth?.get('etdDepartureMergedDateTime')?.value;
        let errorMessage = 'This date/time overlaps with another berth’s stay. Please check and confirm the correct date/time.';
        if (currentEtaActualMergedDateTime && etaActualMergedDateTime && currentEtaActualMergedDateTime.getTime() > etaActualMergedDateTime.getTime()) {
          currentBerth.get('etaActualErrorMessage')?.setValue(errorMessage);
          this.hasErrors = true;
        }
        if (currentEtaActualMergedDateTime && etdDepartureMergedDateTime && currentEtaActualMergedDateTime.getTime() > etdDepartureMergedDateTime.getTime()) {
          currentBerth.get('etaActualErrorMessage')?.setValue(errorMessage);
          this.hasErrors = true;
        }
        this.refactorCheckFutureDateTime(currentEtdDepartureMergedDateTime, errorMessage, etaActualMergedDateTime, etdDepartureMergedDateTime, currentBerth);
      }
    }
  }

  refactorCheckFutureDateTime(currentEtdDepartureMergedDateTime: any, errorMessage: any, etaActualMergedDateTime: any, etdDepartureMergedDateTime: any, currentBerth: any) {
    if (currentEtdDepartureMergedDateTime && etaActualMergedDateTime && currentEtdDepartureMergedDateTime.getTime() > etaActualMergedDateTime.getTime()) {
      currentBerth.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }

    if (currentEtdDepartureMergedDateTime && etdDepartureMergedDateTime && currentEtdDepartureMergedDateTime.getTime() > etdDepartureMergedDateTime.getTime()) {
      currentBerth.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }
  }

  checkEtaEtdValidation(currentIndex: any, berthIndex: any) {
    const currentBerth = this.getArBerths(currentIndex).at(berthIndex);
    const currentEtaActualMergedDateTime = currentBerth?.get('etaActualMergedDateTime')?.value;
    const currentEtdDepartureMergedDateTime = currentBerth?.get('etdDepartureMergedDateTime')?.value;
    const etaEtdtype = currentBerth.get('type')?.value;
    if (currentEtaActualMergedDateTime && currentEtdDepartureMergedDateTime && currentEtaActualMergedDateTime.getTime() > currentEtdDepartureMergedDateTime.getTime()) {
      this.hasErrors = true;
      if (etaEtdtype == 'eta') {
        let errorMessage = 'This date/time should not be after the berth’s departure date/time. Please check and confirm the correct date/time.';
        currentBerth.get('etaActualErrorMessage')?.setValue(errorMessage);
      } else {
        let errorMessage = 'This date/time should not be before the berth’s arrival date/time. Please check and confirm the correct date/time.';
        currentBerth.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      }
    }
  }

  checkPreviousDateTime(currentIndex: any, berthIndex: any) {
    const currentBerth = this.getArBerths(currentIndex).at(berthIndex);
    const currentEtaActualMergedDateTime = currentBerth?.get('etaActualMergedDateTime')?.value;
    const currentEtdDepartureMergedDateTime = currentBerth?.get('etdDepartureMergedDateTime')?.value;

    for (let i = 0; i < berthIndex; i++) {
      const berth = this.getArBerths(currentIndex).at(i);
      const etaActualMergedDateTime = berth?.get('etaActualMergedDateTime')?.value;
      const etdDepartureMergedDateTime = berth?.get('etdDepartureMergedDateTime')?.value;
      let errorMessage = 'This date/time overlaps with another berth’s stay. Please check and confirm the correct date/time.';
      if (currentEtaActualMergedDateTime && etaActualMergedDateTime && currentEtaActualMergedDateTime.getTime() < etaActualMergedDateTime.getTime()) {
        currentBerth.get('etaActualErrorMessage')?.setValue(errorMessage);
        this.hasErrors = true;
      }
      if (currentEtaActualMergedDateTime && etdDepartureMergedDateTime && currentEtaActualMergedDateTime.getTime() < etdDepartureMergedDateTime.getTime()) {
        currentBerth.get('etaActualErrorMessage')?.setValue(errorMessage);
        this.hasErrors = true;
      }
      this.checkEtdDateTime(currentEtdDepartureMergedDateTime, errorMessage, etaActualMergedDateTime, etdDepartureMergedDateTime, currentBerth);
    }
  }

  checkEtdDateTime(currentEtdDepartureMergedDateTime: any, errorMessage: any, etaActualMergedDateTime: any, etdDepartureMergedDateTime: any, currentBerth: any) {
    if (currentEtdDepartureMergedDateTime && etaActualMergedDateTime && currentEtdDepartureMergedDateTime.getTime() < etaActualMergedDateTime.getTime()) {
      currentBerth.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }

    if (currentEtdDepartureMergedDateTime && etdDepartureMergedDateTime && currentEtdDepartureMergedDateTime.getTime() < etdDepartureMergedDateTime.getTime()) {
      currentBerth.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }
  }

  checkAgainstValue(currentIndex: any, berthIndex: any) {
    const currentBerth = this.getArBerths(currentIndex).at(berthIndex);
    const arBerths = this.getArBerths(currentIndex)?.value;
    const etaActualMergedDateTime = currentBerth?.get('etaActualMergedDateTime')?.value;
    const etdDepartureMergedDateTime = currentBerth?.get('etdDepartureMergedDateTime')?.value;
    const berthSeqNo = currentBerth?.get('berthSeqNo')?.value;
    const berthName = currentBerth?.get('berthName')?.value;

    const actualDateArival = this.getArBerths(currentIndex).at(berthIndex)?.get('actualDateArival')?.value;
    const actualDateDeparture = this.getArBerths(currentIndex).at(berthIndex)?.get('actualDateDeparture')?.value;

    for (const berth of arBerths) {
      if ((berth.berthSeqNo !== berthSeqNo && berth.berthName !== berthName) || (berth.berthSeqNo !== berthSeqNo && berth.berthName === berthName) || (berth.berthSeqNo === berthSeqNo && berth.berthName !== berthName)) {
        let errorMessage = 'This date/time conflicts with another field on this form. Please check and confirm the correct date/time.';
        // Same ETA-ETA
        if (!actualDateArival && etaActualMergedDateTime && berth.etaActualMergedDateTime && etaActualMergedDateTime.getTime() == berth.etaActualMergedDateTime.getTime()) {
          currentBerth.get('etaActualErrorMessage')?.setValue(errorMessage);
          this.hasErrors = true;
        }

        this.refactorCheckAgainstValueETD(etdDepartureMergedDateTime, errorMessage, berth, etaActualMergedDateTime, currentBerth, actualDateArival, actualDateDeparture);

      }
    }
  }

  refactorCheckAgainstValueETD(etdDepartureMergedDateTime: any, errorMessage: any, berth: any, etaActualMergedDateTime: any, currentBerth: any,actualDateArival:any, actualDateDeparture:any) {

    // Same ETA-ETD
    if (!actualDateArival && etaActualMergedDateTime && berth.etdDepartureMergedDateTime && etaActualMergedDateTime.getTime() == berth.etdDepartureMergedDateTime.getTime()) {
      currentBerth.get('etaActualErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }

    // Same ETD-ETD
    if (!actualDateDeparture && etdDepartureMergedDateTime && berth.etdDepartureMergedDateTime && etdDepartureMergedDateTime.getTime() == berth.etdDepartureMergedDateTime.getTime()) {
      currentBerth.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }

    // Same ETD-ETA
    if (!actualDateDeparture && etdDepartureMergedDateTime && berth.etaActualMergedDateTime && etdDepartureMergedDateTime.getTime() == berth.etaActualMergedDateTime.getTime()) {
      currentBerth.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }
  }

  getBerthGroupEtaType(type: any): boolean {
    return type == 'eta';
  }

  getBerthGroupEtdType(type: any): boolean {
    return type == 'etd';
  }

  checkApiErrorMessage(berthSeqNo: any, berthGroup: any, actualDateArivalDisplay: any, actualTimeArivalDisplay: any, actualDateDepartureDisplay: any, actualTimeDepartureDisplay: any, portFormGroup: any) {
    if (actualDateArivalDisplay && actualTimeArivalDisplay && actualDateDepartureDisplay && actualTimeDepartureDisplay && !this.hasErrors && !this.hasSameErrors) {
      const berthName = berthGroup.get('berthName')?.value;
      let payload = {
        "vesVoy": portFormGroup.value.vesVoy,
        "portNum": portFormGroup.value.portNo,
        "seqNum": portFormGroup.value.seqNo,
        "berthSeqNum": berthSeqNo,
        "berthName": berthName,
        etaDate: this.toLocalISOFormat(new Date(actualDateArivalDisplay), new Date(actualTimeArivalDisplay)),
        etdDate: this.toLocalISOFormat(new Date(actualDateDepartureDisplay), new Date(actualTimeDepartureDisplay)),
        "portEtaDate": portFormGroup.value.dtETA,
        "portEtdDate": portFormGroup.value.dtETD,
        isEtaDate: this.getBerthGroupEtaType(berthGroup.value.type),
        isEtdDate: this.getBerthGroupEtdType(berthGroup.value.type),
        token: this.tokenLink
      }
      this.activeApiRequests = (this.activeApiRequests || 0) + 1;
      this.hasErrorsApiLevel = true;
      this.etbInternalService.validatePortAgtBerthETdates(payload).subscribe({
        next: (res: any) => {
          // Decrement the counter when a request completes
          this.activeApiRequests -= 1;
          if (this.activeApiRequests === 0) {
            this.hasErrorsApiLevel = false;
          }
          if (!this.hasErrors && !this.hasSameErrors) {
            berthGroup.patchValue({
              etaActualErrorMessage: "",
              etdDepartureErrorMessage: "",
              vslLevelErrorMessage: "",
            });
          }
        }, error: err => {
          let errorType = err.error.error.field;
          // Set error messages
          // Decrement the counter when a request completes
          this.activeApiRequests -= 1;
          this.hasErrorsApiLevel = true;
          if (!this.hasErrors && !this.hasSameErrors) {
            berthGroup.patchValue({
              etaActualErrorMessage: errorType?.etaDate,
              etdDepartureErrorMessage: errorType?.etdDate,
              vslLevelErrorMessage: errorType?.vslLevel,
            });
          }
        }
      })
    }
  }
  // End form code

  checkBerthConditions(portFormArray: any) {
    return portFormArray.every((port: any) =>
      port.arBerths.every((berth: any) => {
        const { etaActualMergedDateTime, etdDepartureMergedDateTime } = berth;
        const actualDateArival = berth.actualDateArival;
        const actualDateDeparture = berth.actualDateDeparture;
        // If both are non-null, return true for this berth
        if (etaActualMergedDateTime && etdDepartureMergedDateTime) {
          return true;
        }

        // If only one of them is null, return false
        if (this.areDatesInconsistent(etaActualMergedDateTime, etdDepartureMergedDateTime, actualDateArival, actualDateDeparture)) {
          return false;
        }

        if ((berth.actualDateArivalDisplay && !berth.actualTimeArivalDisplay) ||
          (!berth.actualDateArivalDisplay && berth.actualTimeArivalDisplay) ||
          (berth.actualDateDepartureDisplay && !berth.actualTimeDepartureDisplay) ||
          (!berth.actualDateDepartureDisplay && berth.actualTimeDepartureDisplay)
        ) {
          return false;
        }

        // If both are null, continue checking other berths
        return true;
      })
    );
  }

  areDatesInconsistent(etaActualMergedDateTime:any, etdDepartureMergedDateTime:any, actualDateArival:any, actualDateDeparture:any) {
    return (!actualDateArival && etaActualMergedDateTime && !etdDepartureMergedDateTime) ||
           (!actualDateDeparture && !etaActualMergedDateTime && etdDepartureMergedDateTime);
  }

  checkError() {
    const berthConditionsValid = this.checkBerthConditions(this.portFormArray.value);
    const hasErrorMessage = this.portFormArray.value.some((port: any) =>
      port.arBerths.some((berth: any) =>
        berth.etaActualErrorMessage || berth.etdDepartureErrorMessage
      )
    );
    return (hasErrorMessage || !berthConditionsValid) ? true : false
  }

  checkEmptyEtaEtd(currentIndex: any, berthIndex: any) {
    const berthResults = this.checkBerthConditionsValue(this.portFormArray.value);
    let errorMessage = '';
    berthResults.filter(item => !item.result).forEach(item => {
      if (berthIndex == item.berthIndex && item.portIndex == currentIndex) {
        const currentBerth = this.getArBerths(item.portIndex).at(item.berthIndex).value;
        if (!currentBerth.actualDateArivalDisplay || !currentBerth.actualTimeArivalDisplay) {
          errorMessage = 'Please enter ETA details for the berth.'
          if (!this.hasErrors && !this.hasSameErrors) {
            this.getArBerths(item.portIndex)?.at(item.berthIndex)?.get('etaActualErrorMessage')?.setValue(errorMessage);
            this.hasErrors = true;
          }
        } else if (!currentBerth.actualDateDepartureDisplay || !currentBerth.actualTimeDepartureDisplay) {
          errorMessage = 'Please enter ETD details for the berth.'
          if (!this.hasErrors && !this.hasSameErrors) {
            this.getArBerths(item.portIndex)?.at(item.berthIndex)?.get('etdDepartureErrorMessage')?.setValue(errorMessage);
            this.hasErrors = true;
          }
        }
      }
    });
  }

  checkEmptyEtaEtdForAll(currentIndex: any) {
    const berthResults = this.checkBerthConditionsValue(this.portFormArray.value);
    let errorMessage = '';
    berthResults.filter(item => !item.result).forEach(item => {
      if (item.portIndex == currentIndex) {
        const currentBerth = this.getArBerths(item.portIndex).at(item.berthIndex).value;
        const actualDateArival = currentBerth.actualDateArival;
        const actualDateDeparture = currentBerth.actualDateDeparture;
        if (!currentBerth.actualDateArivalDisplay || !currentBerth.actualTimeArivalDisplay) {
          errorMessage = 'Please enter ETA details for the berth.'
          if (!actualDateDeparture && !this.hasErrors && !this.hasSameErrors) {
            this.getArBerths(item.portIndex)?.at(item.berthIndex)?.get('etaActualErrorMessage')?.setValue(errorMessage);
          }
        } else if (!currentBerth.actualDateDepartureDisplay || !currentBerth.actualTimeDepartureDisplay) {
          errorMessage = 'Please enter ETD details for the berth.'
          if (!actualDateArival && !this.hasErrors && !this.hasSameErrors) {
            this.getArBerths(item.portIndex)?.at(item.berthIndex)?.get('etdDepartureErrorMessage')?.setValue(errorMessage);
          }
        }
      }
    });
  }


  checkBerthConditionsValue(portFormArray: any[]): { result: boolean; portIndex: number; berthIndex: number }[] {
    let results: { result: boolean; portIndex: number; berthIndex: number }[] = [];

    portFormArray.forEach((port, portIndex) => {
      port.arBerths.forEach((berth: any, berthIndex: number) => {
        const eta = berth.etaActualMergedDateTime;
        const etd = berth.etdDepartureMergedDateTime;

        // Check conditions for eta and etd values
        let result: boolean;
        if (eta && etd) {
          result = true;
        } else if ((eta && !etd) || (!eta && etd)) {
          result = false;
        } else {
          result = true; // Assuming false if both are null
        }

        // Add the result along with the port and berth index to the results array
        results.push({
          result: result,
          portIndex: portIndex,
          berthIndex: berthIndex
        });
      });
    });

    return results;
  }

  // Check berth lie backend prev - 
  checkSameBerthDateLieValidation(currentIndex: any, berthIndex: any) {
    const currentBerth = this.getArBerths(currentIndex).at(berthIndex);
    const currentBerthName = currentBerth?.get('berthName')?.value;
    const currentEtaActualMergedDateTime = currentBerth?.get('etaActualMergedDateTime')?.value;
    const currentEtdDepartureMergedDateTime = currentBerth?.get('etdDepartureMergedDateTime')?.value;
    // Iterate over the portFormArray to check only previous berths
    const currentVesPort = this.portFormArray.value[currentIndex];
    for (let vesPort of this.portFormArray.value) {
      let havingSameBrthNames = vesPort.arBerths.filter((berth: any) => berth.berthName == currentBerthName);
      if (havingSameBrthNames.length && vesPort.vesVoy != currentVesPort.vesVoy) {
        let isETABerthOccupied = havingSameBrthNames.some((berth: any) => currentEtaActualMergedDateTime && berth.etaActualMergedDateTime && berth.etaActualMergedDateTime.getTime() == currentEtaActualMergedDateTime.getTime());
        let isETABerthOccupiedETD = havingSameBrthNames.some((berth: any) => currentEtaActualMergedDateTime && berth.etdDepartureMergedDateTime && berth.etdDepartureMergedDateTime.getTime() == currentEtaActualMergedDateTime.getTime());

        let isETDBerthOccupied = havingSameBrthNames.some((berth: any) => currentEtdDepartureMergedDateTime && berth.etaActualMergedDateTime && berth.etaActualMergedDateTime.getTime() == currentEtdDepartureMergedDateTime.getTime());
        let isETDBerthOccupiedETA = havingSameBrthNames.some((berth: any) => currentEtdDepartureMergedDateTime && berth.etdDepartureMergedDateTime && berth.etdDepartureMergedDateTime.getTime() == currentEtdDepartureMergedDateTime.getTime());

        let isETABerthOccupiedOverlap = havingSameBrthNames.some((berth: any) => currentEtaActualMergedDateTime && berth.etaActualMergedDateTime && berth.etdDepartureMergedDateTime && currentEtaActualMergedDateTime.getTime() > berth.etaActualMergedDateTime.getTime() && currentEtaActualMergedDateTime.getTime() < berth.etdDepartureMergedDateTime.getTime());
        let isETDBerthOccupiedOverlap = havingSameBrthNames.some((berth: any) => currentEtdDepartureMergedDateTime && berth.etaActualMergedDateTime && berth.etdDepartureMergedDateTime && currentEtdDepartureMergedDateTime.getTime() > berth.etaActualMergedDateTime.getTime() && currentEtdDepartureMergedDateTime.getTime() < berth.etdDepartureMergedDateTime.getTime());

        let isSameETABerthOccupiedOverlap = havingSameBrthNames.some((berth: any) => currentEtaActualMergedDateTime && berth.etaActualMergedDateTime && currentEtdDepartureMergedDateTime && currentEtaActualMergedDateTime.getTime() < berth.etaActualMergedDateTime.getTime() && berth.etaActualMergedDateTime.getTime() < currentEtdDepartureMergedDateTime.getTime());
        let isSameETDBerthOccupiedOverlap = havingSameBrthNames.some((berth: any) => currentEtaActualMergedDateTime && berth.etdDepartureMergedDateTime && currentEtdDepartureMergedDateTime && currentEtaActualMergedDateTime.getTime() < berth.etdDepartureMergedDateTime.getTime() && berth.etdDepartureMergedDateTime.getTime() < currentEtdDepartureMergedDateTime.getTime());
       
        this.refactorSameBerthDateLie_1(currentIndex, isETABerthOccupied, isETABerthOccupiedETD);
        this.refactorSameBerthDateLie_2(currentIndex, isETDBerthOccupied, isETDBerthOccupiedETA, isETABerthOccupiedOverlap, isETDBerthOccupiedOverlap, isSameETABerthOccupiedOverlap, isSameETDBerthOccupiedOverlap);
       
      }
    }
  }

  refactorSameBerthDateLie_1(currentIndex:any, isETABerthOccupied:any, isETABerthOccupiedETD:any){
    if (isETABerthOccupied) {
      let etaDate = 'This date/time conflicts with another vessel’s ETA at this berth. Please check and confirm the correct date/time.';
      this.getArBerths(currentIndex)?.at(this.selectBerthIndex)?.get('etaActualErrorMessage')?.setValue(etaDate);
      this.hasErrors = true;
    }else{
      this.clearAllBerthValidationMsg();
    }

    if (isETABerthOccupiedETD) {
      let etaDate = 'This date/time conflicts with another vessel’s ETD at this berth. Please check and confirm the correct date/time.';
      this.getArBerths(currentIndex)?.at(this.selectBerthIndex)?.get('etaActualErrorMessage')?.setValue(etaDate);
      this.hasErrors = true;
    }else {
      this.clearAllBerthValidationMsg();
    }
  }

  refactorSameBerthDateLie_2(currentIndex:any, isETDBerthOccupied:any, isETDBerthOccupiedETA:any, isETABerthOccupiedOverlap:any, isETDBerthOccupiedOverlap:any, isSameETABerthOccupiedOverlap:any, isSameETDBerthOccupiedOverlap:any){
    if (isETDBerthOccupied) {
      let etdDate = 'This date/time conflicts with another vessel’s ETD at this berth. Please check and confirm the correct date/time.';
      this.getArBerths(currentIndex)?.at(this.selectBerthIndex)?.get('etdDepartureErrorMessage')?.setValue(etdDate);
      this.hasErrors = true;
    }else {
      this.clearAllBerthValidationMsg();
    }
    if (isETDBerthOccupiedETA) {
      let etdDate = 'This date/time conflicts with another vessel’s ETA at this berth. Please check and confirm the correct date/time.';
      this.getArBerths(currentIndex)?.at(this.selectBerthIndex)?.get('etdDepartureErrorMessage')?.setValue(etdDate);
      this.hasErrors = true;
    }else {
      this.clearAllBerthValidationMsg();
    }
    if (isETABerthOccupiedOverlap || isSameETABerthOccupiedOverlap) {
      let etaDate = 'Berth is occupied by another vessel at the selected date & time';
      this.getArBerths(currentIndex)?.at(this.selectBerthIndex)?.get('etaActualErrorMessage')?.setValue(etaDate);
      this.hasErrors = true;
    }else {
      this.clearAllBerthValidationMsg();
    }
    if (isETDBerthOccupiedOverlap || isSameETDBerthOccupiedOverlap) {
      let etdDate = 'Berth is occupied by another vessel at the selected date & time';
      this.getArBerths(currentIndex)?.at(this.selectBerthIndex)?.get('etdDepartureErrorMessage')?.setValue(etdDate);
      this.hasErrors = true;
    }else {
      this.clearAllBerthValidationMsg();
    }

  }

  clearAllBerthValidationMsg(){
    if (!this.hasErrors && !this.hasSameErrors) {
      const portFormArrayValue = this.portFormArray.value;
      // Iterate over each port object
      portFormArrayValue.forEach((port: any, portIndex:any) => {
        // Iterate over each berth in the arBerths array
        port.arBerths.forEach((berth: any, berthIndex:any) => {
          // Clear the etaActualErrorMessage and etdDepartureErrorMessage values
          this.getArBerths(portIndex)?.at(berthIndex)?.get('etaActualErrorMessage')?.setValue('');
          this.getArBerths(portIndex)?.at(berthIndex)?.get('etdDepartureErrorMessage')?.setValue('');
          berth.etdDepartureErrorMessage = null;
        });
      });
    }
  }
  // End check berth lie backend


  // Session expiration code start
  startSession() {
    const now = new Date().getTime();
    const expirationTime = now + this.SESSION_DURATION;
    localStorage.setItem('sessionExpiration', expirationTime.toString());
  }

  isSessionValid() {
    const now = Date.now();
    const expirationTimeStr = localStorage.getItem('sessionExpiration');
    const expirationTime = expirationTimeStr ? parseInt(expirationTimeStr, 10) : null;

    if (expirationTime && now < expirationTime) {
      const remainingTime = expirationTime - now;
      if (remainingTime <= this.WARNING_THRESHOLD && !this.openModel) { // 2 minutes in milliseconds
        if(!this.isSessionInactivityModalCloseButtonClicked) this.openModel = true;
      }
      const remainingMinutes = Math.ceil(remainingTime / 60000); 
      console.log(`Session is still valid. Remaining time: ${remainingMinutes} minutes (${remainingTime} milliseconds)`);
      return true;
    } else {
        if(!this.isSessionExpired) this.closeSession();
        return false;
    }
  }

  getRemainingTime() {
    const now = Date.now();
    const expirationTimeStr = localStorage.getItem('sessionExpiration');
    const expirationTime = expirationTimeStr ? parseInt(expirationTimeStr, 10) : null;

    if (expirationTime && now < expirationTime) {
      return expirationTime - now;
    } else {
      return 0;
    }
  }

  // Function to reset the session expiration time on user interaction
  @HostListener('window:click')
  @HostListener('window:keydown')
  @HostListener('window:scroll')
  @HostListener('window:mousemove')
  resetSessionExpiration() {
    if (this.resetDebounceTimeout) {
      clearTimeout(this.resetDebounceTimeout);
    }
    this.resetDebounceTimeout = setTimeout(() => {
      if(!this.isSessionOngoing) {
          const remainingTime = this.getRemainingTime();
          if (remainingTime > 120000)  {
              const now = new Date().getTime();
              const expirationTime = now + this.SESSION_DURATION;
              localStorage.setItem('sessionExpiration', expirationTime.toString());
              this.isSessionExpired = false;
          }
      }
    }, 2000); // Debounce for 2 seconds
  }

  extendSession() {
    this.openModel = false;
    this.startSession();
  }

  async closeSession() {
    try {
      console.log("Session closed function called!!");
      await this.updateTokenInfo(this.tokenLink);
      this.openModel = false;
      sessionStorage.removeItem('isMainTab');
      localStorage.removeItem('sessionExpiration');
      this.isSessionExpired = true; // Add this line
  } catch (error) {
      console.error('Error closing session:', error);
  }
  }

  async updateTokenInfo(token:any){
    return new Promise((resolve, reject) => {
      console.log("Token info update function called!!");
      const tokenData = { 
        token:token, 
        data: { 
          isLinkOpen:false, 
          sessionEndDateTime: new Date().toISOString(),
          otp: null,
          attemptCount: 0,
          wrongOTPCount: 0,
          portAgentEtbUpdateCount: 0
        } 
      };
      const requestBody = this.navigationService.objectToRequestBody(tokenData);
      const res = this.otpService.updateTokenInfo(requestBody);
      resolve(res);
    });
  }

  closeSessionInactivityModal() {
    this.openModel = false;
    this.isSessionInactivityModalCloseButtonClicked = true;
  }

  ngOnDestroy() {
    if (this.sessionCheckInterval) {
      clearInterval(this.sessionCheckInterval);
    }
    if (this.resetDebounceTimeout) {
      clearTimeout(this.resetDebounceTimeout);
    }
    if(!this.isSessionOngoing && this.isMainTab !== 'true') {
      console.log("ngOnDestroy called!!");
      this.closeSession();
    }
  } 

  @HostListener('window:unload', ['$event'])
  onUnload(event: Event) {
    event.preventDefault(); 
    console.log("Window unload event called!!");
    if(!this.isSessionOngoing && this.isMainTab === 'true') {
      console.log("Close Session"); 
      this.closeSession();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event) {
    console.log("Window beforeunload event called!!");
    if(!this.formSubmitted && !this.isSessionExpired) event.preventDefault(); // Necessary to trigger the browser's default prompt
  }
  // Session expiration code END

  buttonSubmitCheck() {
    this.portFormArray.value.some((port: any, currentIndex:any) =>{
      port.arBerths.forEach((berth: any, berthIndex: any) => {
            const actualDateArivalDisplay = berth.actualDateArivalDisplay;
            const actualTimeArivalDisplay = berth.actualTimeArivalDisplay;
            const actualDateDepartureDisplay = berth.actualDateDepartureDisplay;
            const actualTimeDepartureDisplay = berth.actualTimeDepartureDisplay;
            const mergedArrival = this.mergeDateAndTime(actualDateArivalDisplay, actualTimeArivalDisplay);
            const mergedDeparture = this.mergeDateAndTime(actualDateDepartureDisplay, actualTimeDepartureDisplay);
            if(actualDateArivalDisplay && actualTimeArivalDisplay && actualDateDepartureDisplay && actualTimeDepartureDisplay){
              this.getArBerths(currentIndex)?.at(berthIndex)?.get('etaActualMergedDateTime')?.setValue(mergedArrival);
              this.getArBerths(currentIndex)?.at(berthIndex)?.get('etdDepartureMergedDateTime')?.setValue(mergedDeparture);
            }
          })
          this.checkDateOrder(port.arBerths, currentIndex)
        })
        this.portFormArray.value.some((port: any, portIndex:any) =>
          port.arBerths.some((berth: any) =>{
            if(berth.etaActualErrorMessage || berth.etdDepartureErrorMessage){
              this.etbList[portIndex].isExpanded = true;
            }
          }
          )
        );
  }

    // Check overlapping without sequence 
    checkOverlappingSequence(currentIndex: any, berthIndex: any) {
      const currentBerth = this.getArBerths(currentIndex).at(berthIndex);
      const currentEtaActualMergedDateTime = currentBerth?.get('etaActualMergedDateTime')?.value;
      const currentEtdDepartureMergedDateTime = currentBerth?.get('etdDepartureMergedDateTime')?.value;

      const currentVesPort = this.portFormArray.value[currentIndex];
      let checkAllBerth = [ ...currentVesPort.arBerths.slice(0, berthIndex), ...currentVesPort.arBerths.slice(berthIndex + 1) ];
      let isETABerthOccupiedOverlap = checkAllBerth.some((berth: any) => currentEtaActualMergedDateTime && berth.etaActualMergedDateTime && berth.etdDepartureMergedDateTime && currentEtaActualMergedDateTime.getTime() > berth.etaActualMergedDateTime.getTime() && currentEtaActualMergedDateTime.getTime() < berth.etdDepartureMergedDateTime.getTime());
      let isETDBerthOccupiedOverlap = checkAllBerth.some((berth: any) => currentEtdDepartureMergedDateTime && berth.etaActualMergedDateTime && berth.etdDepartureMergedDateTime && currentEtdDepartureMergedDateTime.getTime() > berth.etaActualMergedDateTime.getTime() && currentEtdDepartureMergedDateTime.getTime() < berth.etdDepartureMergedDateTime.getTime());
      let isETABerthOccupiedOverlap1 = checkAllBerth.some((berth: any) => currentEtaActualMergedDateTime && berth.etaActualMergedDateTime && currentEtdDepartureMergedDateTime && currentEtaActualMergedDateTime.getTime() < berth.etaActualMergedDateTime.getTime() && currentEtdDepartureMergedDateTime.getTime() > berth.etaActualMergedDateTime.getTime());
      let isETDBerthOccupiedOverlap2 = checkAllBerth.some((berth: any) => currentEtdDepartureMergedDateTime && currentEtaActualMergedDateTime && berth.etdDepartureMergedDateTime && currentEtaActualMergedDateTime.getTime() < berth.etdDepartureMergedDateTime.getTime() && currentEtdDepartureMergedDateTime.getTime() > berth.etdDepartureMergedDateTime.getTime());
    
    if (isETABerthOccupiedOverlap || isETABerthOccupiedOverlap1) {
      let etaDate = 'This date/time overlaps with another berth’s stay. Please check and confirm the correct date/time.';
      this.getArBerths(currentIndex)?.at(berthIndex)?.get('etaActualErrorMessage')?.setValue(etaDate);
      this.hasErrors = true;
    }
    if (isETDBerthOccupiedOverlap || isETDBerthOccupiedOverlap2) {
      let etdDate = 'This date/time overlaps with another berth’s stay. Please check and confirm the correct date/time.';
      this.getArBerths(currentIndex)?.at(berthIndex)?.get('etdDepartureErrorMessage')?.setValue(etdDate);
      this.hasErrors = true;
    }
  }
  
}
