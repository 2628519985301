<div class="DetailsTransshipMain PriorItineraryMain" [ngClass]="{'HidePriorItinerary':isShowPriorItinerary}">
    <ul class="ShowPriorPortsMain">
        <li></li>
        <li><span (click)="ShowPriorItinerary()">{{isShowPriorItinerary ? 'Show prior ports' : 'Hide prior ports'}}</span></li>
    </ul>
    <ul class="prioritinerary" *ngFor=" let b of !isTransshipment ? bookingDetailData.slice(0, -1) : bookingDetailData; let last = last;index as i">
        <li class="border-muted VoyageNumberVsl">
            <span class="fw-700 font-14 VoyageNumb" *ngIf="b?.idVoyageNumber else tsVoyageTbd">{{b.idVoyageNumber}}</span>
            <ng-template #tsVoyageTbd>
                <div class="headingTBDVsl VoyageNumb MobileTBD_F13">
                    <app-tbd (responsiveTbdTooltip)="tbdTooltip($event)"></app-tbd>
                </div>
             </ng-template>
        </li>
        <li [ngClass]="{'straightLine VesselTrcSpace':true,
        'heght-straightLine':delay(bookingVariance ==='All' ?b.totalDelayInDays:b.delayDaysInLast24Hrs)}">
            <div class="SmallDevicesBx">
                <span [ngClass]="{'portArrow':b.isCurrentPort && !b.isDepartured && trackPageData.bookingStatus !=='Completed' && !last,
                'reachedArrow':b.isCurrentPort &&  b.isDepartured && trackPageData.bookingStatus !=='Completed' && !last,
                'reachedArrowHideBlueLine':b.isCurrentPort &&  b.isDepartured && trackPageData.bookingStatus !=='Completed' && !last}"></span>
                <span class="PortCalls">
                    <span class="loadPortNameHld">
                        {{b.portName|titlecase|removeCountry}} 
                    </span>
                    <em>{{b.country|titlecase}}</em> 
                </span>

                <!-- Mobile View Start Here -->
                <div class="SmallViewInfo">
                    <div class="TopCnt">
                        <p> 
                            <span>
                                <p>Port arrival & departure:</p>
                                <span class="mb-1">
                                    {{b.dtPlannedETA|date:'d
                                    MMM
                                    y, a':'UTC'
                                    }}
                                    {{b.isPortCompleted
                                    ||b.isCurrentPort ?''
                                    :b.dtPlannedETA?'(Est.)':''}} 
                                </span>
                                <span>
                                    {{b.dtPlannedETD|date:'d
                                    MMM
                                    y, a':'UTC'
                                    }}
                                    {{b.isPortCompleted
                                    ||b.isCurrentPort ?''
                                    :b.dtPlannedETD?'(Est.)':''}}
                                </span> 
                            </span>   
                        </p>      
                        <p class="PortTimeSMoblie">
                            <span class="ArrivalTxt">{{b.etaDelay|delayMessage}}</span>
                            <span class="PillsHolder LargeDeviceshide m-0"> 
                                <ng-container *ngIf="b.etaDelay">
                                    <ng-container *ngFor="let item of delayObj">
                                            <snl-pills *ngIf="item.min <= b.etaDelay && item.max > b.etaDelay"   [type]="'outline'" [layout]="item.cls">{{(b.etaDelay>0 && b.etaDelay<1) ?(b.etaDelay> 0.5 ? '1
                                                day':(b.etaDelay*24|number:'1.0-0'|hours)):((b.etaDelay < 0 && b.etaDelay> -1)? (b.etaDelay < -0.5) ?'-1
                                                        day':(b.etaDelay*24|number:'1.0-0'|hours):b.etaDelay|number:'1.0-0'|days)}}</snl-pills>
                                    </ng-container>
                                </ng-container> 
                            </span>
                        </p> 
                    </div>
                    <div class="TopCnt BtmCnt mt-2">
                        <div class="CargosBerth">
                            <div class="CargosInr">
                                <span class="ArrivalTxt">Berth arrival & departure:</span>
                                <div class="CargosInrData"> 
                                    <span class="BlankBG BlankBGstatic"></span>
                                    <span class="BlankBG BlankBGstatic"></span>
                                    <span class="BlankBG BlankBGstatic"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="TopCnt BtmCnt mt-2">
                        <div class="CargosBerth">
                            <div class="CargosInr">
                                <span class="ArrivalTxt">Cargoes:</span>
                                <div class="CargosInrData"> 
                                    <span class="BlankBG BlankBGstatic"></span>
                                    <span class="BlankBG BlankBGstatic"></span>
                                    <span class="BlankBG BlankBGstatic"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="TopCnt BtmCnt mt-2">
                        <div class="CargosBerth">
                            <div class="CargosInr">
                                <span class="ArrivalTxt">Qty:</span>
                                <div class="CargosInrData"> 
                                    <span class="BlankBG BlankBGstatic"></span> 
                                    <span class="BlankBG BlankBGstatic"></span> 
                                    <span class="BlankBG BlankBGstatic"></span> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Mobile View End Here --> 
            </div>

        </li>

        <li class="SmallDeviceshide"
            [ngClass]="{TextSpaceBtm:delay(bookingVariance === 'All'?b.totalDelayInDaysETA:b.delayDaysInLast24HrsETA)}">
            <ng-container *ngIf="b.dtPlannedETA || b.dtPlannedETD" >
            <span class="SpaceUtili flex-row Smallfont">
                {{b.dtPlannedETA|date:'d
                MMM y, a':'UTC'
                }}
                {{b.isPortCompleted ||b.isCurrentPort ?''
                :b.dtPlannedETA?'(Est.)':''}}
            </span>
            <span class="SpaceUtili">{{b.dtPlannedETD|date:'d
                MMM
                y, a':'UTC'
                }} {{b.isPortCompleted ||b.isCurrentPort ?''
                :b.dtPlannedETD?'(Est.)':''}}
            </span>
            <div class="PortTimeS"> 
                <span class="ArrivalTxt font-16">{{b.etaDelay|delayMessage}}</span> 
                <ng-container *ngIf="b.etaDelay">
                    <ng-container *ngFor="let item of delayObj">
                        <snl-pills *ngIf="item.min <= b.etaDelay && item.max > b.etaDelay"  [type]="'outline'" [layout]="item.cls">{{(b.etaDelay>0 && b.etaDelay<1) ?(b.etaDelay> 0.5 ? '1
                            day':(b.etaDelay*24|number:'1.0-0'|hours)):((b.etaDelay < 0 && b.etaDelay> -1)? (b.etaDelay <= -0.5) ?'-1
                                    day':(b.etaDelay*24|number:'1.0-0'|hours):b.etaDelay|number:'1.0-0'|days)}}</snl-pills>
                    </ng-container>
                </ng-container> 
            </div>
        </ng-container>    
        <ng-container *ngIf="!b.dtPlannedETA && !b.dtPlannedETD">
            <span class="d-flex">
                <span>
                    <app-tbd></app-tbd>
            </span>                                                                            
            </span>
        </ng-container> 
        </li> 
        <li class="SmallDeviceshide">
            <div class="CargoesVesselTb"> 
                <span class="BlankBG BlankBGstatic"></span>
                <span class="BlankBG BlankBGstatic"></span>
                <span class="BlankBG BlankBGstatic"></span>
            </div>
        </li>
        <li class="SmallDeviceshide">
            <div class="CargoesVesselTb"> 
                <span class="BlankBG BlankBGstatic"></span>
                <span class="BlankBG BlankBGstatic"></span>
                <span class="BlankBG BlankBGstatic"></span>
            </div>
        </li>
        <li>
            <div class="CargoesVesselTb QTYRightAlign"> 
                <span class="BlankBG BlankBGstatic"></span>
                <span class="BlankBG BlankBGstatic"></span>
                <span class="BlankBG BlankBGstatic"></span>
            </div>
        </li>
    </ul>
</div>