import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';

const routes: Routes = [
  // { path: "", redirectTo: "/auth", pathMatch: "full" },
  // {
  //   path: "auth",
  //   loadChildren: () => import("./auth/auth.module").then(m => m.AuthModule)
  // },
  {
    path: "pages",
    loadChildren: () => import("./pages/pages.module").then(m => m.PagesModule),
  },
  {
    path: "sustainability",
    loadChildren: () => import("./sustainability/sustainability.module").then(m => m.SustainabilityModule),
    canActivate: [AuthGuard]
  },
  {
    path: "user",
    loadChildren: () => import("./user-management/user-management.module").then(m => m.UserManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: "documentation",
    loadChildren: () => import("./documentation/documentation.module").then(m => m.DocumentationModule),
    canActivate: [AuthGuard]
  },
  {
    path: "api",
    loadChildren: () => import("./api-documentation/api-documentation.module").then(m => m.ApiDocumentationModule),
    canActivate: [AuthGuard]
  },
  {
    path: "etb-internal",
    loadChildren: () => import("./etb-internal/etb-internal.module").then(m => m.EtbInternalModule),
    canActivate: [AuthGuard]
  },
  {
    path: "announcements",
    loadChildren: () => import("./announcements/announcements.module").then(m => m.AnnouncementsModule),
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontRoutingModule { }
