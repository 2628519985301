export const environment = {
  production: true,
  API_BASE_URL: 'https://api.stolt.com/stdigital',
  B2C_CLIENTID:'b5854ef8-5a86-49fe-b04c-f4d485d581d2',
  AUTHORITY:'https://stoltb2c.b2clogin.com/stoltb2c.onmicrosoft.com/B2C_1A_STDIGITAL_SIGNUP_SIGNIN',
  KNOWNAUTHORITIES:['stoltb2c.b2clogin.com'],
  USERSCOPE:'https://stoltb2c.onmicrosoft.com/76a254fb-e47e-41e7-92ab-94d36b15e2b3/read',
  GRAPH_ENDPONT:'https://graph.microsoft.com/v1.0/me',
  REDIRECT_URI:'https://myst.stolttankers.com/berths-schedules',
  POST_LOGOUT_REDIRECT_URI:'https://myst.stolttankers.com',
  FRONTEND_GOOGLE_MAPS:'AIzaSyD-JpB5eMpvA___hJrmGB0I95DzpI8w37w',
  AUTH_SERVICE_BASE_NAME:'/auth',
  BOOKING_SERVICE_BASE_NAME:'/booking',
  MASTER_SERVICE_BASE_NAME:'/master',
  SUSTAINABILITY_SERVICE_BASE_NAME:'/sustainability',
  VESSELTRACKING_SERVICE_BASE_NAME:'/vessel-tracking',
  CAPTCHAKEY:'6LewyRclAAAAAMGZlIO1zt9EPb-2gFrhFxYNBc6j',
  appInsights: {
    instrumentationKey: 'fc16b931-7228-4742-9e08-fbb77899939b'
  },
  navType:'production',
  googleAnalytic:'production',
  googleAnalyticKey:'G-C2QR2XDPT7',
  googleTagManager:'GTM-TMMW96C',
  googleAnalyticUAKey:'UA-225453928-2',
  DOCUMENTATION_BASE_URL: 'https://app.laytimedesk.com/pdms-api/external/download',
  DOCUMENTATION_POC_KEY:"ebef5516-2505-4229-ae4b-c11aacc380e0",
  POOLING_PORTAL_URL: "https://poolingportal.stolttankers.com/dashboard",
  apiurl:'https://myst.stolttankers.com',
  DIMENSIONS:{
    'dimension1': 'userCompany',
    'dimension2': 'userName',
    'dimension3': 'userType'},
    GLOBALCOMPANY : 5943082716,
    IP_URL:"https://ipwhois.app/json/",
    USERSEMAIL :['ANSHIKA@WHIZ-SOLUTIONS.COM','HONEY@WHIZ-SOLUTIONS.COM','HNP@stolt.com','ahg@stolt.com','ish@stolt.com','GAI@STOTL.COM','HML@stolt.com','NSA@STOLT.COM','VTY@STOLT.COM','RKM@STOLT.COM','AGP@STOLT.COM',"ACL@STOLT.COM",'IRF@STOLT.COM','OHO@STOLT.COM','OIS@STOLT.COM,"MIR@STOLT.COM','NGI@STOLT.COM','YLM@STOLT.COM'],
    bookingFilterRegex:/ *\([^)]*\) */g,
    googleVerificationKey:"test",
    SURVEY_MONKEY_WEBLINK: 'https://www.surveymonkey.com/r/7F8Z6HW',
    secretKeyOtp : 'e44fc9c0bb6e7a21dd0409cba6f4280b',
    FULL_CANLENDAR_KEY: '0795550318-fcs-1738947948'
};
