<!-- Page Content Holder -->
<div id="content" class="StoltBGDefault w-100" [ngClass]="{'overFlowHidden01':overFlowClass}">
    <form #userlogin="ngForm" (keydown.enter)="$event.preventDefault()">
        <div class="StoltCommonContentArea BgCommonContentArea2"> 
           <!-- Page Title -->
            <snl-page-title>
                <div title-content> 
                    <span class="header-h1">Settings</span> 
                </div> 
            </snl-page-title>

            <!-- Page Content Card -->
            <div class="CommonCardContent SettingPageHolder position-relative p-0 bg-transparent"> 
                <!-- Accordion Snl -->
                <snl-accordion
                    [showArrow]="true"
                    [settings]="settings"
                    [isTitleClickable]="true"
                    [expanded]="true">
                    <div class="header-h2" header-content>
                        Distribution lists 
                    </div>

                    <div>
                        <div class="description-acr d-flex flex-column body-b1">
                            <span id="tableDescription">Manage multiple recipients by assigning their emails to lists.</span>
                            <div class="CsvFIleText d-flex flex-column">
                                <span> Create new lists either manually or by uploading a CSV file.
                                    <a (click)="downloadTemplate()">Download CSV template</a>
                                </span>
                                <span class="inline-block">Download CSV template CSV files must contain <strong>‘name’</strong> (optional) and <strong>‘email’</strong> (mandatory) columns and can have a maximum of 25 entries</span>
                            </div> 
                        </div>
                        <div class="d-flex CreateUploadBtn"> 
                            <snl-button [type]="'secondary'" [size]="'large'"  (click)="isOpenModel = true;resetForm()">
                                <svg-icon class="df-c" name="plus-icon2"></svg-icon>
                                Create new list 
                            </snl-button>

                            <div class="file__choose">
                                <label class="df-c fw-700" for="upload-file">
                                    <svg-icon name="upload-icon" class="df-c"></svg-icon> 
                                    Upload CSV
                                </label>
                                <input #myInput class="StoltBtnIconLarge StoltBtnBorderOnly" type="file" id="upload-file"
                                    accept=".csv" (change)="handleFileSelect($event)">

                                <div class="messageShow"> 
                                    <a class="error" *ngIf="checkError" (click)="downloadCSV()">Error File</a>
                                </div>
                            </div>
                            <span class="error">{{fileExtenMessg}}</span>
                        </div>

                        <!-- Snl Table Code Start Here -->
                        <snl-table class="distribution-snl-tbl">
                            <snl-row>
                                <snl-th class="body-b1 fw-700">
                                    Distribution List
                                </snl-th>
                                <snl-th class="body-b1 fw-700 flex-center-data">
                                    No. of recipients
                                </snl-th>
                                <snl-th class="body-b1 fw-700 flex-center-data">
                                    Actions 
                                </snl-th>
                            </snl-row>
                            <ng-container class="border-0" *ngIf="DistSourceData.length > 0 && !isDistributionLoading;">
                                <snl-row *ngFor="let listdata of DistSourceData">
                                    <snl-td class="border-0 listName">
                                        <a href="javascript:void(0)" (click)="isOpenModel = true;UpdateList(listdata)" 
                                            class="truncate-text body-b1">
                                            {{listdata.listName}}
                                        </a>
                                    </snl-td>
                                    <snl-td class="border-0 flex-center-data">
                                        <p class="m-0 text-end truncate-text body-b1">
                                            {{(listdata.recipientList) ?
                                            listdata.recipientList.length : '0'}}
                                        </p> 
                                    </snl-td>
                                    <snl-td class="border-0 flex-center-data">
                                        <a href="javascript:void(0)" (click)="distributionRemoveModel = true;DeleteList(listdata)"
                                            class="trash__list df-c fw-700 body-b1">
                                            <svg-icon name="trash-icon" class="m-0 df-c"></svg-icon>
                                            Delete
                                        </a> 
                                    </snl-td>
                                </snl-row> 
                            </ng-container>
                            <ng-container *ngIf="isDistributionLoading">
                                <snl-row *ngFor="let row of [0,1,2,3,4]">
                                    <snl-td class="border-0"><span class="Stolt-Skeleton w-100"></span></snl-td>
                                    <snl-td class="border-0 text-end"><span class="Stolt-Skeleton w-50 ms-auto"></span></snl-td>
                                    <snl-td class="border-0 text-center"><span class="Stolt-Skeleton w-100"></span></snl-td>
                                </snl-row> 
                            </ng-container>
                        </snl-table>

                        <!-- Don’t have any lists -->
                        <ng-template [ngIf]="DistSourceData.length == 0 && !isDistributionLoading">
                            <div class="DataNotAvailable text-center border-0 my-5 w-100">
                                <span class="w-100 justify-content-center"> You don’t have any lists yet, create one to start! </span>
                            </div>
                        </ng-template> 
 
                        <!-- Mobile code start here -->
                        <div class="MobileBoxesListMain w-100" *ngIf="DistSourceData.length > 0">
                            <div class="MobileBoxesList" *ngFor="let listdata of DistSourceData">
                                <div class="columntxt" >
                                <span class="title-line body-b m-0">Distribution list</span>
                                <a href="javascrip:void(0);" (click)="isOpenModel = true;UpdateList(listdata)" class="LinkTxt section-title">{{listdata.listName}}</a>
                                </div>

                                <div class="columntxt">
                                    <span class="title-line body-b m-0">No. of recipients</span>
                                    <p class="body-b1 m-0">{{(listdata.recipientList) ?
                                        listdata.recipientList.length : '0'}}</p>
                                </div>

                                <div class="columntxt align-items-end">
                                    <a href="javascript:void(0)"  (click)="distributionRemoveModel = true;DeleteList(listdata)" class="d-flex LinkTxt trash__list">
                                        <svg-icon class="d-flex" name="trash-icon"></svg-icon> 
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </snl-accordion>

                <!-- Accordion Snl -->
                <snl-accordion
                    [showArrow]="true"
                    [settings]="settings"
                    [isTitleClickable]="true"
                    [expanded]="false">
                    <div header-content class="header-h2">
                        Email notifications
                    </div>

                    <div>
                        <div class="description-acr d-flex flex-column body-b1">
                            <span>Customise content and frequency of ETA update notifications.</span>
                        </div>
                        <div class="CreateNotifMdl d-flex"> 
                            <snl-button *ngIf="!notifiactionSkelton" class="M_CommonHidden" [type]="'secondary'" [size]="'large'" (click)="CreateNotificaton()">
                                <svg-icon name="plus-icon2"></svg-icon>
                                Create new notification
                            </snl-button>

                            <!-- Skelton Button --> 
                            <div *ngIf="notifiactionSkelton" class="M_CommonHidden SkeltonForBtn Stolt-Skeleton"> </div>

                             <!-- Mobile Button -->   
                            <snl-button class="CreateNewNotBtn LargeDeviceshide w-100" [type]="'secondary'" [size]="'large'" (click)="CreateMobileNotificaton()">
                                <svg-icon name="plus-icon2"></svg-icon>
                                Create new notification
                            </snl-button>
                        </div>

                        <!-- Snl Table Code Start Here -->                                        
                        <snl-table class="distribution-snl-tbl Notification-snl-tbl">
                            <snl-row>
                                <snl-th class="body-b1 fw-700">
                                    Notification
                                </snl-th>
                                <snl-th class="body-b1 fw-700">
                                    Event
                                </snl-th>
                                <snl-th class="body-b1 fw-700">
                                    Filter
                                </snl-th>
                                <snl-th class="body-b1 fw-700">
                                    Time
                                </snl-th>
                                <snl-th class="body-b1 fw-700">
                                    Actions
                                </snl-th>
                            </snl-row>
                            <ng-container class="border-0" *ngIf="NotificationListData.length > 0 ">
                                <snl-row *ngFor="let Notifival of NotificationListData">
                                    <snl-td class="border-0" (click)="UpdateNotificaton(Notifival)">
                                        <a href="javascript:void(0)" class="truncate-text">
                                            {{Notifival.notificationTitle}} 
                                        </a>
                                    </snl-td>
                                    <snl-td class="border-0 body-b1">
                                        <p class="m-0 truncate-text">{{Notifival.events}}</p> 
                                    </snl-td>
                                    <snl-td class="border-0 body-b1">
                                        <p class="m-0 truncate-text">{{Notifival.mergeFilter ? (Notifival.mergeFilter) : Notifival.dateFilters ? Notifival.dateFilters : 
                                            '&mdash;'}}
                                        </p> 
                                    </snl-td>
                                    <snl-td class="border-0 body-b1">
                                        <p class="m-0">{{Notifival.ConverTime ? Notifival.ConverTime : 'Immediate'}}</p>
                                    </snl-td>
                                    <snl-td class="border-0 text-center">
                                        <a href="javascript:void(0)"  (click)="notificationRemoveModel = true;DeleteNotificationList(Notifival.id)"  class="trash__list df-c fw-700 body-b1">
                                            <svg-icon class="m-0 df-c" name="trash-icon"></svg-icon>
                                            Delete
                                        </a>
                                    </snl-td>
                                </snl-row>  
                            </ng-container>
                            <ng-container *ngIf="notifiactionSkelton">
                                <snl-row *ngFor="let row of [0,1,2,3,4]">
                                    <snl-td class="border-0"><span class="Stolt-Skeleton w-75"></span></snl-td>
                                    <snl-td class="border-0 text-start"><span class="Stolt-Skeleton w-50"></span></snl-td>
                                    
                                    <snl-td class="border-0 text-center"><span class="Stolt-Skeleton w-100"></span></snl-td>
                                    <snl-td class="border-0 text-start"><span class="Stolt-Skeleton w-75"></span></snl-td>
                                    <snl-td class="border-0 text-center"><span class="Stolt-Skeleton w-100"></span></snl-td>
                                </snl-row>
                                
                            </ng-container>
                        </snl-table> 

                        <!-- Don’t have any lists -->
                        <ng-template [ngIf]="NotificationListData.length == 0 && !notifiactionSkelton">
                            <div class="DataNotAvailable text-center border-0 my-5 w-100">
                                <span class="w-100 justify-content-center"> You don’t have any lists yet, create one to start! </span>
                            </div>
                        </ng-template> 

                        <!-- Mobile Code Start Here -->
                        <div class="MobileBoxesListMain" *ngIf="NotificationListData.length > 0">
                            <div class="MobileBoxesList" *ngFor="let Notifival of NotificationListData">
                                <div class="columntxt flex-row justify-content-between align-items-center col_gap_8">
                                    <div class="NotifiTxtHead d-flex flex-column">
                                    <span class="title-line body-b m-0">Notification</span>
                                    <a href="javascrip:void(0);" (click)="UpdateMobileNotificaton(Notifival)" class="LinkTxt body-b2">{{Notifival.notificationTitle}}</a>
                                    </div>
                                    <a href="javascript:void(0)" (click)="notificationRemoveModel = true;DeleteNotificationList(Notifival.id)" class="d-flex LinkTxt trash__list">
                                        <svg-icon class="d-flex" name="trash-icon"></svg-icon> 
                                    </a>
                                </div>
                                <div class="columntxt">
                                    <span class="title-line body-b m-0">Event</span>
                                    <p class="body-b1 m-0">{{Notifival.events}}</p>
                                </div>
                                <div class="columntxt">
                                    <span class="title-line body-b m-0">Filter</span>
                                    <p class="body-b1 m-0">{{Notifival.mergeFilter ? (Notifival.mergeFilter) : '-'}}</p>
                                </div>
                                <div class="columntxt">
                                    <span class="title-line body-b m-0">Time</span>
                                    <p class="body-b1 m-0">{{Notifival.ConverTime ? Notifival.ConverTime : '-'}}</p>
                                </div> 
                            </div>
                        </div>
                    </div>
                </snl-accordion>   
                
                <!-- Accordion Snl -->
                <snl-accordion
                    [showArrow]="true"
                    [settings]="settings"
                    [isTitleClickable]="true"
                    [expanded]="false" class="accordion-item SettingHeader VarianceAccordion p-0 border-0">
                    <div header-content class="header-h2">
                        ETA variance definition
                    </div>
                    <div class="SettingPills pt-0">
                        <div class="InnerPillsHld InnerPillsHld-ipad">
                            <span><em class="CircleNotification GreenCircle"></em> Less than</span>
                            <span class="form-group">
                                <input type="number" id="" class="eta-varience" #min
                                    (keyup)="preventKeyEnter($event,'min')"
                                    (change)="setBetweenData(min.value,max.value)" [(ngModel)]="obj.min_delay_days"
                                    placeholder="" name="obj.min_delay_days" class="form-control" min="1" max="99">
                            </span>
                            <span>{{obj.min_delay_days > 1 ? 'days' : 'day'}}</span>
                        </div>
                        <div class="InnerPillsHld InnerPillsHld-ipad">
                            <span><em class="CircleNotification RedCircle"></em> More than</span>
                            <span class="form-group">
                                <input type="number" id="" class="eta-varience" #max
                                    (keyup)="preventKeyEnter($event,'max')"
                                    (change)="setBetweenData(min.value,max.value)" [(ngModel)]="obj.max_delay_days"
                                    placeholder="" name="obj.max_delay_days" class="form-control" min="1" max="99">
                            </span>
                            <span>{{obj.max_delay_days > 1 ? 'days' : 'day'}}</span>
                        </div>
                        <div class="InnerPillsHld InnerPillsHld-ipad border-0 p-0">
                            <span><em class="CircleNotification CircleOrange"></em> Between</span>
                            <span class="form-group">
                                <strong class="form-control d-flex df-c">{{minVal}} - {{maxVal}}</strong>
                            </span>
                            <span>days</span>
                        </div>
                    </div>
                </snl-accordion>   
                
                <!-- Accordion Snl --> 
                <snl-accordion [ngClass]="{'isFilterOpen':isFilterOpenClose}"
                    [showArrow]="true"
                    [settings]="settings"
                    [isTitleClickable]="true"
                    [expanded]="false">
                    <div header-content class="header-h2">
                        Favourite filters
                    </div>
                    <div class="description-acr FavouriteFilters d-flex flex-column body-b1">
                        <div class="d-flex align-items-start justify-content-between dis__textmain">
                            <div class="d-flex flex-column">
                                <span>By default, filters remember and retain what was the last used selection, except for ETBs.</span>
                                <span>Overwrite this behaviour by selecting your favourite filters.</span>
                            </div>
                            <span class="ClearFltr" [ngClass]="{'resetdisabled' : btnDisabled}">
                                <a (click)="SettingfilterPocComponent.ResetAllFilter()" class="section-title text-end w-100 text-decoration-none">Clear filter settings</a>
                            </span> 
                        </div>
                        <app-settingfilter-poc (filterCallBack)="getUserfilterByEmit($event)" (filterEventCallBack)="clickedEvent($event)" [settingFilterData]="settingFilterData"></app-settingfilter-poc>                                  
                    </div>
                </snl-accordion>  
            </div>
        </div> 

        <!-- Sticky action panel Snl -->
        <snl-action-panel class="Sticky__ActionPanel">
            <div left-side> 
                <snl-button [type]="'primary'" (click)="onClickSubmit(userlogin.value)" [size]="'large'" [disabled]="isDisabled">
                    <span *ngIf="!isLoading" class="section-title">Save Changes</span> 
                    <span *ngIf="isLoading" class="section-title" [ngClass]="{isDisabled:isLoading}">
                        <em class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading}"></em>
                        Saving..
                    </span>
                </snl-button>  
            </div> 
        </snl-action-panel> 
    </form>   
</div>



 
<!-- SNL library model notification setup -->
<app-notification-setup-poc *ngIf="isNotification" [isNotification]="isNotification" [stoltEmail]="stoltDomain" [editRowNotification]="updateNotification" [settingFilterData]="settingFilterData" (emitClaseNoti)="closePopupNoti($event)" [min]="minVal" [max]="maxVal" [shareType]="'setting'" [defaulyTimezone]="defaulyTimezone" [timezoneList]="timezoneList" (emitOverflowClass)="emitOverflowClass($event)"></app-notification-setup-poc>

<!-- SNL library model Distribution list -->
<snl-modal *ngIf="isOpenModel" [settings]="snlModalSettings"  (modalClose)="closeDistribution()" [type]="'medium'" [open]="isOpenModel" class="Modal__DIstributionlist CommonChnagesMdl">
    <div slot="heading" class="header-h2">List properties</div>
    <div slot="modalContent"> 
        <div class="ListPropertiesFields mb-0 flex-column"> 
            <p class="mb-0 font-14 fw-500 mobileMargin-b">List Name <em class="req">*</em></p>
            <form [formGroup]="listnamForm">
                <div class="d-flex dflt__Fileds listNameDiv">
                    <div class="w-100">
                        <snl-input
                            [label]="'List Name'"
                            [errorMessage]="(listnamForm.controls.listname.touched || listnamForm.controls.listname.dirty)&& listnamForm.controls.listname.invalid ? 'List Name is required' : existError ? 'List Name already Exist':''"
                            formControlName="listname"
                            onKeyPress="if(this.value.length==40) return false;"
                            (keyup)="OnlistNameChange($event)" 
                            [isError]="(listnamForm.controls.listname.touched || listnamForm.controls.listname.dirty) && listnamForm.controls.listname.invalid || existError"
                            [type]="'text'"
                        ></snl-input> 
                        <div class="pl-0 caption inp__helperText inp__helperText--error errorMessage">{{textLenMsg}}</div>
                    </div>    
                    <div class="w-100">
                        <p class="mb-0 body-b1">
                            <strong>Tip:</strong> Activate lists by typing the
                            hash # sign in email fields
                        </p>
                    </div>
                </div>
            </form>
        </div>
        <div class="List___Resipiants" [ngClass]="{'disable': listnameExist.length > 0}">
            <div class="list__res">
                <p class="font-18 fw-400 mb-0">List recipients ({{respintLenght}})</p>
            </div>

            <div *ngIf="manulaEnb">
                <form [formGroup]="userForm">
                    <div class="dflt__Fileds" *ngIf="disableOnEdit">
                        <div class="ListPropertiesFields notifyLabels">
                            <div class="form-floating w__m50 M_CommonHidden">
                                <p class="mb-0 font-14 fw-500">Name <em class="req">*</em></p>
                            </div>
                            <div class="form-floating w__m50 M_CommonHidden">
                                <p class="mb-0 font-14 fw-500">Email <em class="req">*</em> </p>
                            </div>
                        </div>
                        <div class="ListPropertiesFields">
                            <div class="parent-input-snl w-100">
                                <div class="LargeDeviceshide">
                                    <p class="mb-2 font-14 fw-500">Name <em class="req">*</em> </p>
                                </div>
                                <snl-input class="w-100" [label]="'Name'" [errorMessage]="(userForm.controls.name.touched || userForm.controls.name.dirty) && userForm.controls.name.invalid ? 'Name is required' : checkhashName ? nameValidError:''" formControlName="name" autocomplete="off" (keyup)="OnKeyupName($event)"
                                [isError]="(userForm.controls.name.touched || userForm.controls.name.dirty) && userForm.controls.name.invalid || checkhashName"
                                ></snl-input>
                                <div class="error" *ngIf="checkErrorUserForm('name', 'name')">Name is required</div>
                            </div>

                            <div class="parent-input-snl w-100">
                                <div class="LargeDeviceshide">
                                    <p class="mb-2 font-14 fw-500">Email <em class="req">*</em> </p>
                                </div> 

                                <snl-input class="w-100" [label]="'Email'" formControlName="email" autocomplete="off"
                                    [errorMessage]="'Please enter the valid email'"
                                    [isError]="(userForm.controls.email.touched || userForm.controls.email.dirty) && userForm.controls.email.invalid"
                                    (change)="onDuplicateEmail1($event,0);"
                                    (focusout)="functionName(resplenght)" tabindex="-1">
                                </snl-input>
    
                                <a class="trash-field" *ngIf="Is_frmDelete" href="javascript:void(0)" 
                                (click)="removeForm()">
                                    <svg-icon name="trash-icon"></svg-icon>
                                </a>
                            </div>
                             
                            <div class="ad__btn" *ngIf="ReferralsFormArr.length == 0">
                                <button [disabled]="!userForm.valid || !Is_add" (click)="addReferral();">
                                    <svg-icon name="plus-icon"></svg-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="adingMor__Fileds">
                        <div class="adingMor__in" formArrayName="Referrals">
                            <div class="ListPropertiesFields notifyLabels" *ngIf="!disableOnEdit">
                                <div class="form-floating w__m50 M_CommonHidden">
                                    <p class="mb-0 font-14 fw-500">Name <em class="req">*</em></p>
                                </div>
                                <div class="form-floating w__m50 M_CommonHidden">
                                    <p class="mb-0 font-14 fw-500">Email <em class="req">*</em> </p>
                                </div>
                            </div>
                            <div
                                *ngFor="let referral of ReferralsFormArr.controls; let i = index ; let last = last">
                                <div [formGroupName]="i">
                                    <div class="ListPropertiesFields">
                                        <div class="parent-input-snl w-100">
                                            <div class="LargeDeviceshide">
                                                <p class="mb-2 font-14 fw-500">Name <em class="req">*</em> </p>
                                            </div> 
                                            <div class="w-100"> 
                                                <snl-input class="w-100" 
                                                    [label]="'Name'"
                                                    [errorMessage]="getReferralsFormArr(i).controls['name'].touched && getReferralsFormArr(i).controls['name'].hasError('required') ? 'Name is required' : getReferralsFormArr(i).controls['name'].touched && getReferralsFormArr(i).controls['name'].hasError('minlength') ? 'Name should atleast contain 3 characters' : getReferralsFormArr(i).controls['name'].touched && getReferralsFormArr(i).controls['name'].hasError('maxlength') ? 'Name should max contain 100 characters' : getReferralsFormArr(i).controls['name'].touched && getReferralsFormArr(i).controls['name'].hasError('pattern') ? 'Hash(#) special character are not allowed' : ''"
                                                    formControlName="name"
                                                    [isError]="getReferralsFormArr(i).controls['name'].touched && getReferralsFormArr(i).controls['name'].hasError('required')"
                                                    [type]="'text'">
                                                </snl-input>
                                            </div>
                                        </div>

                                        <div class="parent-input-snl w-100">
                                            <div class="LargeDeviceshide">
                                                <p class="mb-2 font-14 fw-500">Email <em class="req">*</em> </p>
                                            </div> 
                                            <div class="w-100">   
                                                <snl-input class="w-100" 
                                                    [label]="'Email'"
                                                    [errorMessage]="getReferralsFormArr(i).controls['email'].touched && getReferralsFormArr(i).controls['email'].hasError('required') ?'Please enter the email as emails are mandatory to add' : 'Please enter the valid email'"
                                                    formControlName="email"
                                                    [isError]="getReferralsFormArr(i).controls['email'].touched && getReferralsFormArr(i).controls['email'].hasError('required')"
                                                    [type]="'email'"
                                                    (keyup)="OnkeyUp($event)"
                                                    (change)="onDuplicateEmail($event,i+1)"
                                                    (focusout)='functionName(resplenght)' tabindex="-1">
                                                </snl-input>
                                                
                                                <div *ngIf="!Is_add && last" class="error errMessagecstm">
                                                    {{errMessage}}
                                                </div>
                                            </div>

                                            <a *ngIf="Is_Delete" class="trash-field"
                                                href="javascript:void(0)"
                                                (click)="removeReferral(i)">
                                                <svg-icon name="trash-icon"></svg-icon>
                                            </a> 
                                        </div>
                                        <div class="ad__btn" *ngIf="last">
                                            <button [disabled]="!userForm.valid || !Is_add"
                                                (click)="addReferral()">
                                                <svg-icon name="plus-icon"></svg-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="respLimit" class="adingMor__Fileds mt-0">
                        <div class="ListPropertiesFields">
                            <div class="form-floating mt-0"> </div>
                            <div class="form-floating mt-0 mt__m16">
                                <div class="error errMessagecstm">{{errMessagelimit}}</div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div> 
    </div>
    <div class="MdlFooter" slot="modalFooter">
        <snl-button [disabled]="!userForm.valid || !Is_add || is_upload || existError || isDelete" size="large" (click)="onSubmit(userForm.value)">Save list properties</snl-button>
        <snl-button [type]="'secondary'" size="large" (buttonClick)="closeDistribution()">Cancel</snl-button>
    </div>
</snl-modal>

<!-- Mobile Custom Model -->
<app-notification-setup *ngIf="isMobileNotification" [stoltEmail]="stoltDomain" [editRowNotification]="updateNotification" [settingFilterData]="settingFilterData" (emitClaseNoti)="closePopupNoti($event)" [min]="minVal" [timezoneList]="timezoneList" [max]="maxVal" [shareType]="'setting'" [defaulyTimezone]="defaulyTimezone" (emitOverflowClass)="emitOverflowClass($event)"></app-notification-setup>

<!--  SNL library model Delete Distribution  list  -->
<snl-modal (modalClose)="distributionRemoveModel = false" [settings]="snlModalSettings" [type]="'small'" [open]="distributionRemoveModel" class="CommonChnagesMdl">
    <div slot="heading"><strong>Delete List?</strong></div>
    <div class="body-b" slot="modalContent">
        There are {{totalRecipients}} recipients in your list.
   </div>
    <div slot="modalFooter">
        <snl-button size="large" type="secondary" (click)="distributionRemoveModel = false">Cancel</snl-button>
        <snl-button class="DeleteBtnBG" size="large" (click)="DeleteList('del')" [disabled]="isDelete">Yes, Delete</snl-button>
    </div>
</snl-modal>

<!-- SNL library model Delete  notification  list -->
<snl-modal (modalClose)="notificationRemoveModel = false" [settings]="snlModalSettings" [type]="'small'" [open]="notificationRemoveModel" class="CommonChnagesMdl">
    <div slot="heading"><strong>Delete Notification?</strong></div>
    <div class="body-b" slot="modalContent">
        Are you sure want to delete this notification.
   </div>
    <div slot="modalFooter">
        <snl-button size="large" type="secondary" (click)="notificationRemoveModel = false">Cancel</snl-button>
        <snl-button class="DeleteBtnBG" size="large" (click)="DeleteNotificationList('del')" [disabled]="isDelete">Yes, Delete</snl-button>
    </div>
</snl-modal>

<!-- SNL library model CSV Upload -->
<snl-modal (modalClose)="openppop = false" [type]="'small'" [open]="openppop" class="CommonChnagesMdl">
    <div slot="heading"><strong>Upload List</strong></div>
    <div slot="modalContent">
        Are you sure want to upload <strong>{{csvFileName}}</strong> file
   </div>
    <div slot="modalFooter">
        <snl-button size="large" type="secondary" (click)="openppop = false;closePopup()">Cancel</snl-button>
        <snl-button size="large" type="primary" (click)="uploadcsvdata(UploadfilesData)">Yes</snl-button>
    </div>
</snl-modal>