<!-- Page Content Holder -->
<div id="content" class="StoltBGDefault h-100">
    <div class="StoltWrapper">
        <div  class="StoltCommonContentArea" *ngIf="!isSessionOngoing && !isSessionExpired">
            <div class="booking-heading">
                <div class="booking-heading-left" *ngIf="etbList.length"> 
                    <snl-page-title>
                        <div title-content>
                            <span class="header-h1">ETBs updates</span>
                        </div>
                    </snl-page-title>

                    <div class="HeaderCommonTextMain">   
                        <div class="HeaderCommonText body-b1">
                            <p>Ensure that ETB updates are accurate and up-to-date as these details will be reflected to customers via the customer portal.</p>
                            <p>Note: ETAs and ETDs to be updated should be as per the local berth timing.</p>
                        </div>
                    </div>
                </div>

                 <!-- Title Skelton -->
                <div class="booking-heading-left TitleSkeletonTop" *ngIf="!etbList.length"> 
                   <div class="Stolt-Skeleton __HeadingSkelton"></div>

                  <div class="HeaderCommonTextMain">   
                    <div class="Stolt-Skeleton "></div>
                    <div class="Stolt-Skeleton "></div>
                  </div>
              </div>
            </div>

          <!-- Page Content Card -->
          <div class="CommonCardContent" *ngIf="etbList.length">
              <ng-container *ngFor="let li of etbList; let i=index">
                <snl-accordion
                    [settings]="settings"
                    [expanded]="li.isExpanded"
                    [ngClass]="{'not_allowed': !li.arBerths.length}"
                    (click)="isDisableBtn = false"
                    >
                    <div header-content>
                        <div class="AccordionHing">
                            <div class="sub-heading-s1">{{li.vslName | titlecase}}</div>
                            <snl-pills [type]="'outline'" [layout]="'error'" *ngIf="li.status == 'Pending updates'">{{li.status}}</snl-pills>
                            <snl-pills [type]="'outline'" [layout]="'success'" *ngIf="li.status && li.status != 'Pending updates' && li.status != 'No action required'">{{li.status}}</snl-pills>
                        </div>
                        <div class="MoreInfoHdr">
                            <div class="MoreInfoHdr">
                                <div class="MoreInfoHdrInner body-b">
                                    <span class="NameLgt">Voyage no.:</span>
                                    <strong>{{li.voyNo}}</strong>
                                </div>
                                <div class="MoreInfoHdrInner body-b"> 
                                    <strong>{{li.portName}}, {{li?.country | titlecase}}</strong>
                                </div>
                                <div class="MoreInfoHdrInner EtaEtdInsideMain body-b">
                                    <div class="EtaEtdInside">
                                        <span class="NameLgt">ETA:</span>
                                        <strong>{{li?.dtETA | date: 'd MMM yyyy, HH:mm' : 'UTC'}}</strong>
                                    </div>
                                    <span class="PipeLineInr">|</span>
                                    <div class="EtaEtdInside">
                                        <span class="NameLgt">ETD:</span>
                                        <strong>{{li?.dtETD | date: 'd MMM yyyy, HH:mm' : 'UTC'}}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div>
                      <!--Form code-->
                      <div class="RightBarDatePicker">
                        <div class="RightBarDatePickerBody">
                          <ng-container *ngFor="let portFormValue of portFormArray.controls; let i = index">
                            <div *ngIf="li._id == portFormValue.get('_id')?.value">
                              <form [formGroup]="portFormValue">
                                <div class="InnerBodyDateMain">
                                  <div class="InnerBodyDate">
                                    <div class="InformationHldrMn" formArrayName="arBerths">
                                      <div class="InformationHldr" *ngFor="let berth of portFormValue.get('arBerths').controls; let j = index" [formGroupName]="j">
                                        <div class="InnerDataMainBody">
                                          <div class="InnerInfoData">
                                            <div>
                                              <div>
                                                <div class="section-title">{{ berth.get('berthName')?.value|titlecase }}</div>
                                                <div class="CargoNmemain">
                                                  <ng-container formArrayName="arCargoes">
                                                    <div class="CargoNme" *ngFor="let cargo of getCargoes(berth).controls; let k = index"
                                                      [formGroupName]="k">
                                                      <span class="badge_LD">{{ cargo.get('func')?.value|titlecase }}</span>
                                                      <span class="body-b1">{{ cargo.get('cargo')?.value|titlecase }}</span>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                                <div class="DateTimeColmnHolder">
                                                  <div class="InnerDateTimeCl">
                                                    <div class="body-b1">Estimated / Actual Berthing (ETA / ATA)</div>
                                                    <div class="DateTimePickerMain">
                                                      <div class="DateColumn"
                                                        [ngClass]="{'disabled':berth.get('actualTimeArivalDisable')?.value, 'error-message': (berth.get('etaActualErrorMessage')?.value && !berth.get('actualTimeArivalDisable')?.value)}">
                                                        <mat-form-field appearance="fill" (click)="picker1.open();currentTimerIndex=null;">
                                                          <span class="FloatingLbl">Date</span>
                                                          <input matInput [matDatepicker]="picker1" formControlName="actualDateArivalDisplay"
                                                            (ngModelChange)="handleErrorMessage(i,'eta', j)">
                                                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                          <mat-datepicker #picker1>
                                                            <mat-datepicker-actions>
                                                              <button class="StoltBtnIconLarge position-relative overflow-hidden DATEfilterapplybtn" matDateRangePickerApply>Apply</button>
                                                              <button class="StoltBtnIconLarge StoltBtnBorderOnly DATErestebtn" matDateRangePickerCancel>Cancel</button>  
                                                          </mat-datepicker-actions>
                                                          </mat-datepicker>
                                                        </mat-form-field>
                                                        <span class="formatted-date">
                                                          {{ berth.get('actualDateArivalDisplay')?.value | date: 'dd MMM yyyy' }}
                                                        </span>
                                                      </div>
                                                      <div class="CustomSlectTime"
                                                        [ngClass]="{'FloatingLblMng active':berth.get('actualTimeArivalDisplay')?.value, 'disabled':berth.get('actualTimeArivalDisable')?.value, 'error-message': (berth.get('etaActualErrorMessage')?.value && !berth.get('actualTimeArivalDisable')?.value)}">
                                                        <div class="CustomSlectTimeIn" (click)="displayPicker(i, 'eta', j)">
                                                          <span>
                                                            <span class="FloatingLbl">Time</span>
                                                            <strong class="fw-700">
                                                              {{ berth.get('actualTimeArivalDisplay')?.value | date: 'HH:mm' }}
                                                            </strong>
                                                          </span>
                                                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                              d="M7.25 3.75C7.25 3.34375 7.5625 3 8 3C8.40625 3 8.75 3.34375 8.75 3.75L8.75 7.625L11.4062 9.375C11.75 9.625 11.8437 10.0938 11.5937 10.4375C11.375 10.7813 10.9062 10.875 10.5625 10.625L7.5625 8.625C7.375 8.5 7.25 8.25 7.25 8V3.75ZM8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5C4.40625 1.5 1.5 4.4375 1.5 8Z"
                                                              fill="#788496" />
                                                          </svg>
                                                        </div>
                                                      </div>
                                                      <div class="TimePickerMain" *ngIf="i == currentTimerIndex && currentTimerType == 'eta' && j == berthIndex">
                                                        <div class="BodyTimePic">
                                                          <timepicker [hourStep]="hstep" [mousewheel]="false" [minuteStep]="mstep"
                                                            [showMeridian]="false" formControlName="actualTimeArival"></timepicker>
                                                        </div>
                                                        <div class="modal-footer snl-btn-applyCncl">
                                                          <snl-button size="large" (click)="applyTimeCustom(i,'eta', j)">Apply</snl-button>
                                                          <snl-button size="large" [type]="'secondary'" (click)="displayPicker(i, 'close', j)">
                                                            Cancel </snl-button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div *ngIf="berth.get('etaActualErrorMessage')?.value && !berth.get('actualTimeArivalDisable')?.value" class="error-message">
                                                      {{ berth.get('etaActualErrorMessage')?.value }}
                                                    </div>
                                                  </div>
                                                  <div class="InnerDateTimeCl">
                                                    <div class="body-b1">Estimated / Actual Unberthing (ETD / ATD)</div>
                                                    <div class="DateTimePickerMain">
                                                      <div class="DateColumn" [ngClass]="{'disabled':berth.get('actualTimeDepartureDisable')?.value, 'error-message':((berth.get('etdDepartureErrorMessage')?.value || berth.get('vslLevelErrorMessage')?.value) && !berth.get('actualTimeDepartureDisable')?.value)}">
                                                        <mat-form-field appearance="fill" (click)="picker2.open();currentTimerIndex=null;">
                                                          <span class="FloatingLbl">Date</span>
                                                          <input matInput [matDatepicker]="picker2" formControlName="actualDateDepartureDisplay"
                                                            (ngModelChange)="handleErrorMessage(i,'etd', j)">
                                                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                          <mat-datepicker #picker2>
                                                            <mat-datepicker-actions>
                                                              <button class="StoltBtnIconLarge position-relative overflow-hidden DATEfilterapplybtn" matDateRangePickerApply>Apply</button>
                                                              <button class="StoltBtnIconLarge StoltBtnBorderOnly DATErestebtn" matDateRangePickerCancel>Cancel</button>  
                                                          </mat-datepicker-actions>
                                                          </mat-datepicker>
                                                        </mat-form-field>
                                                        <span class="formatted-date">
                                                          {{ berth.get('actualDateDepartureDisplay')?.value | date: 'dd MMM yyyy' }}
                                                        </span>
                                                      </div>
                                                      <div class="CustomSlectTime"
                                                        [ngClass]="{'FloatingLblMng active':berth.get('actualTimeDepartureDisplay')?.value, 'disabled':berth.get('actualTimeDepartureDisable')?.value, 'error-message':((berth.get('etdDepartureErrorMessage')?.value || berth.get('vslLevelErrorMessage')?.value) && !berth.get('actualTimeDepartureDisable')?.value)}">
                                                        <div class="CustomSlectTimeIn" (click)="displayPicker(i, 'etd', j)">
                                                          <span>
                                                            <span class="FloatingLbl">Time</span>
                                                            <strong class="fw-700">
                                                              {{ berth.get('actualTimeDepartureDisplay')?.value | date: 'HH:mm' }}
                                                            </strong>
                                                          </span>
                                                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                              d="M7.25 3.75C7.25 3.34375 7.5625 3 8 3C8.40625 3 8.75 3.34375 8.75 3.75L8.75 7.625L11.4062 9.375C11.75 9.625 11.8437 10.0938 11.5937 10.4375C11.375 10.7813 10.9062 10.875 10.5625 10.625L7.5625 8.625C7.375 8.5 7.25 8.25 7.25 8V3.75ZM8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5C4.40625 1.5 1.5 4.4375 1.5 8Z"
                                                              fill="#788496" />
                                                          </svg>
                                                        </div>
                                                      </div>
                                                      <div class="TimePickerMain" *ngIf="i == currentTimerIndex && currentTimerType == 'etd' && j == berthIndex">
                                                        <div class="BodyTimePic">
                                                          <timepicker [hourStep]="hstep" [mousewheel]="false" [minuteStep]="mstep"
                                                            [showMeridian]="false" formControlName="actualTimeDeparture"></timepicker>
                                                        </div>
                                                        <div class="modal-footer snl-btn-applyCncl">
                                                          <snl-button size="large" (click)="applyTimeCustom(i,'etd', j)">Apply</snl-button>
                                                          <snl-button size="large" [type]="'secondary'" (click)="displayPicker(i, 'close', j)">
                                                            Cancel </snl-button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div *ngIf="berth.get('etdDepartureErrorMessage')?.value && !berth.get('actualTimeDepartureDisable')?.value" class="error-message">
                                                      {{ berth.get('etdDepartureErrorMessage')?.value }}
                                                    </div>
                                                    <div *ngIf="berth.get('vslLevelErrorMessage')?.value && !berth.get('actualTimeDepartureDisable')?.value" class="error-message">
                                                      {{ berth.get('vslLevelErrorMessage')?.value }}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div> <!-- end ngFor for arBerths -->
                                            </div>
                                          </div>
                                        </div>
                                      </div> <!-- end ngFor for arPorts -->
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </ng-container>
                          
                        </div>
                      </div>
                      <!--End form code-->
                  </div>    
                </snl-accordion>
            </ng-container>
            <snl-button class="width-fc" (click)="onSubmit()" [type]="'primary'" [size]="'large'" [disabled]="checkError() || isDisableBtn">
              <span class="SaveDesktop">Submit</span>
            </snl-button>
            </div> 
             
            <!-- Page Skeleton Card -->
            <div class="CommonCardContent SkeletonAcordMain" *ngIf="!etbList.length">
              <div class="Stolt-Skeleton SkeletonAcordBx"></div>
              <div class="Stolt-Skeleton SkeletonAcordBx"></div>
              <div class="Stolt-Skeleton SkeletonAcordBx"></div>
              <div class="ButtonSkeleton"><span class="Stolt-Skeleton"></span></div>
            </div>

        </div> 

        <main class="StoltMainContent w-100 d-flex justify-content-center align-items-center CommonPhoneContainer"> 
          <!-- Session: This area represents the Session expired -->
            <div class="CommonStaticPageText" *ngIf="isSessionExpired">
                <div class="header-h1">Session expired</div>
                <div class="body-b1">
                    <p>Your session has expired due to inactivity.</p>
                    <p>To access this page again, please check your email and click on the link sent.</p>
                </div> 
            </div>
            <!-- Session: This area represents the Session ongoing -->
            <div class="CommonStaticPageText" *ngIf="isSessionOngoing">
              <div class="header-h1">Active session ongoing</div>
              <div class="body-b1">
                <p>An active session for this link is currently ongoing by another user.</p>
                <p>To ensure the security and integrity of the data, only one user can access this page at a time.</p>
                <p>Please try again later once the current session has ended.</p>
              </div> 
          </div>
        </main>

        <!-- Footer Section: This section represents the footer of the webpage -->
        <app-footer></app-footer>
        <app-scroll-to-top></app-scroll-to-top>
    </div>
</div> 


<!-- Model: This model represents the Session expiring -->
<snl-modal class="CenterModelCmn CommonPhoneCstm SessionExpiringMdl" [settings]="snlModalSettings" (modalClose)="closeSessionInactivityModal()" [type]="'small'" [open]="openModel">
    <div slot="heading"><strong>Session  expiring</strong></div>
    <div slot="modalContent">
        <div slot="modalContent" class="SessionExpiringBody">
            <p>Your session will expire in 2 minutes due to inactivity.</p>
            <p>Please select “Extend” to continue your session, or “Close” to close this session.</p>
        </div> 
    </div>
    <div slot="modalFooter">
        <snl-button size="large" (click)="extendSession()">Extend</snl-button>
        <snl-button size="large" type="secondary" (click)="closeSession()">Close</snl-button>
    </div>
</snl-modal> 



