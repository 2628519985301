import { Component, Inject, OnInit } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { PublicPagesService } from '../../../services/public-pages.service';
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { Observable } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  navType = environment.navType;
  trackIdForm: UntypedFormGroup;
  isLogedin: Observable<boolean>;
  istrackIdFormClick = false;
  imo_class = [];
  average_year = 0;
  totalFleets1 = 0;
  totalFleets2 = 0;
  totalFleets3 = 0;
  swiperConfiguration: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoplay: false,
    autoWidth: true,
    margin: 24,
    dots: false,
    navSpeed: 700,
    navText:['<span class="swi-icon icon-arrow-left-3 Text-100 heading-regular-40"></span>','<span class="swi-icon icon-Arrow_Right_3 Text-100 heading-regular-40"></span>'],
    responsive: {
      0: {
        items: 1.3
      },
      400: {
        items: 1.3
      },
      500: {
        items: 2
      },
      940: {
        items: 3
      }
    },
    nav: false
  };
  Isunsubscribe: any = false;
  profileData: any;
  showMonkeySurvey: boolean;
  userId:any;
  isPreview:boolean;
  title:any;
  editorData:any;
  isShowAncmt:any;
  isAnnouncementClosed:any;
  userEmail: any;
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,public navigationService: NavigationService, private fb: UntypedFormBuilder, private publicPageService: PublicPagesService,private authService: MsalService, private msalBroadcastService: MsalBroadcastService,private activatedRoute: ActivatedRoute,private router : Router) {
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers');
    let isLogin = localStorage.getItem('userInfo');
    if(isLogin){
      this.getUserInfo();
    }
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(paramsId => {
      if(paramsId.flag){
      let matches = paramsId.flag.match(/[a-zA-Z]+/g);
      if(matches == 'unsubscibe'){
         this.publicPageService.updateNotifications(paramsId.token).subscribe(
          {
            next:res=>{
              if(res.data && res.data.data){
                this.navigationService.showSuccess(res.data.data.message, '');
                this.router.navigate(['/'])
              }},
            error:err=>{
              this.navigationService.errorOccure(err);
                this.router.navigate(['/'])
            }
          }
      )
      }else{
        this.navigationService.showError("Something went wrong. Please try again after sometime", '');
        this.router.navigate(['/'])
      }
    } 
    })

    this.isLogedin = this.navigationService.isLoginGet();
    this.publicPageService.getHomeFleetInfo().subscribe((res: any) => {
      if (res && res.data) {
        this.imo_class = (res.data.imo_class).filter((e: string) => e);
        this.average_year = res.data.average_year;
        this.totalFleets1 = res.data.totalFleets1;
        this.totalFleets2 = res.data.totalFleets2;
        this.totalFleets3 = res.data.totalFleets3;
      }
    });
  }

  getUserInfo(){
    this.navigationService.getUserInfo().subscribe((res: any) => {
      this.userEmail = res.data.email;
      this.isShowAncmt = localStorage.getItem('show_announcement');
      this.isAnnouncementClosed = res.data.isAnnouncementClosed;
        // if (res?.data?.surveyOpenCount < 3 && !this.navigationService.isSurveyMonkey && !res?.data?.isSurveySubmitted) {
        //   let isStoltDomain = res.data.email.toLowerCase().includes('@stolt');
        //   let isSuperAdmin = res.data.role === 'super_admin';
        //   if (isStoltDomain && isSuperAdmin || !isStoltDomain) {
        //     this.navigationService.isSurveyMonkey = true;
        //     this.showMonkeySurvey = true;
        //     this.userId = res.data.user_id;
        //   } else{
        //     this.openAnnouncement();
        //   }
        // }else{
          this.openAnnouncement();
        //  }
    })
  }

  openAnnouncement(){
    if (this.isShowAncmt || (!this.isShowAncmt && !this.isAnnouncementClosed)) {
      this.getActiveAnnouncement();
    }
  }

  onSurveyClosed(value: string) {
    if(value){
      this.openAnnouncement();
    }
  }

  getActiveAnnouncement(){
    this.navigationService.getActiveAnnouncement().subscribe((res: any) => {
       if(res.status == 200){
        this.title = res.data.title;
        this.editorData = res.data.content;
        this.isPreview = true;
       }
    })
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      localStorage.setItem('show_announcement', 'yes');
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      localStorage.setItem('show_announcement', 'yes');
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }
}
