
<!-- Header Section: This section represents the header of the webpage -->
<app-about-header></app-about-header>

<!-- Common page title section -->
<div class="CommonHeroArea ContactTitleArea position-relative">
    <div class="LargeWindowWidth">
        <div class="CommonHeroContent ">
            <h1 class="heading01">Fleet information</h1> 
        </div>
    </div>
</div>     

<!-- Common Content Section -->
<section class="CommonContentSec">
    <div class="LargeWindowWidth">
       <div class="SecInner">  
            <div class="TextContent">
                <div class="BoxTopInfo">  
                    <ul class="BoxTopInfoInner">
                        <li [ngClass]="{'heading03':true,'Stolt-Skeleton':isSkelton}">
                            <h3 class="heading03" *ngIf="!isSkelton">Total fleet strength</h3>
                        </li>

                        <li [ngClass]="{'heading04':true,'Stolt-Skeleton':isSkelton}">
                            <h4 class="heading04" *ngIf="!isSkelton">{{totalFleets > 0 ? totalFleets : 0}}</h4>
                        </li> 
                    </ul>

                    <ul class="BoxTopInfoInner InActive">
                        <li [ngClass]="{'heading03':true,'Stolt-Skeleton':isSkelton}">
                            <h3 class="heading03" *ngIf="!isSkelton">Now displaying</h3>
                        </li>

                        <li [ngClass]="{'heading04':true,'Stolt-Skeleton':isSkelton}">
                            <h4 class="heading04" *ngIf="!isSkelton">{{displayFleets > 0 ? displayFleets : 0}}</h4>
                        </li>  
                    </ul>                   
                </div>

                <div class="FilterBxLightGrey" *ngIf="!isSkelton">
                    <h3 class="heading03">Filters <a href="javascript:void(0)" (click)="clearFilter()">CLEAR FILTERS</a></h3>
                    <p class="GrapghTitle">Capacity (Dead Weight Tonnes)</p>
                    <div class="CapacityGraphM">
                        <div class="CapacityGraph"> 
                            <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options" (userChangeEnd)="filterData()"></ngx-slider>
                        </div> 
                        <div class="FieldsArea">
                            <div class="FieldsInner"> 
                                <div class="form-group"> 
                                    <input type="text" [(ngModel)]="minValue" class="form-control form-control-stolt" id="" placeholder="15,000" name="">
                                </div> 
                            </div>
                            <div class="FieldsInner"> 
                                <div class="form-group"> 
                                    <input type="text" [(ngModel)]="maxValue" class="form-control form-control-stolt" id="" placeholder="90,000" name="">
                                </div> 
                            </div>                        
                        </div> 
                    </div>
                </div> 

                <!-- Column Content Stolt Skeleton Start -->
                <div class="FilterBxLightGrey Stolt-SkeletonBx" *ngIf="isSkelton">
                    <div class="Stolt-Skeleton"></div>
                </div>  
            </div>

            <div class="ImgArea map-fleetinfo">
                <!-- Map Column Start -->
                <app-agm-map *ngIf="fleetList.length > 0" class="FleetMapFirst" [mapType]="'fleetmultiple'" [fleetArray]="fleetList"></app-agm-map>
                <div class="Stolt-Skeleton" *ngIf="isSkelton"></div>
            </div>
       </div>  
    </div>     
</section> 

<!-- Common Content Section -->
<section class="CommonContentSec TableFleetData" *ngIf="!isSkelton">
    <div class="LargeWindowWidth CommonTableWithMobileBx">
        <!-- Common Table Data --> 
        <div class="snl-table-holder" infiniteScroll [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50" (scrolled)="onScroll()">  
            <ng-scrollbar
            #scrollable
            track="vertical" 
            pointerEventsMethod="scrollbar"
            [autoHeightDisabled]="false">
                <snl-table class="snl-table-customized">
                    <snl-row class="body-b1 fw-700 sticky-tbl-hdr">
                        <snl-th>
                            <span class="TheadeContent"> 
                                Vessel name   
                                <span
                                    [ngClass]="{'shortingIcons position-relative': true,
                                    'active': sortInprogress.column5.isActive,
                                    'ascending-icon':sortInprogress.column5.isActive && sortInprogress.column5.value === 'asc','descending-icon':sortInprogress.column5.isActive && sortInprogress.column5.value === 'desc'}"
                                    (click)="toogleSortingOrder('column5')">
                                    <svg-icon name="asc-dsc"></svg-icon>
                                    <app-sorting *ngIf="sortInprogress.column5.isOpen"
                                        [isActive]="sortInprogress.column5.isActive"
                                        [data]="[{ 'name': 'Ascending', 'val': 'asc' }, { 'name': 'Descending', 'val': 'desc' }]"
                                        [sortType]="sortInprogress.column5.value" [colName]="'column5'"
                                        (sortingType)="fetchSortingType($event)"
                                        (popUpMouseOut)="popUpMouseLeaveClose($event)"></app-sorting>
                                </span>
                            </span>
                        </snl-th>
                        <snl-th>Next destination</snl-th>
                        <snl-th>Current position</snl-th> 
                        <snl-th class="text-flex-end">
                            <span class="TheadeContent justify-content-end"> 
                                Capacity (DWT) 
                                <span
                                    [ngClass]="{'shortingIcons position-relative': true,
                                    'active': sortInprogress.summer_dwt.isActive,
                                    'ascending-icon':sortInprogress.summer_dwt.isActive && sortInprogress.summer_dwt.value === 'asc','descending-icon':sortInprogress.summer_dwt.isActive && sortInprogress.summer_dwt.value === 'desc'}"
                                    (click)="toogleSortingOrder('summer_dwt')">
                                    <svg-icon name="asc-dsc"></svg-icon>
                                    <app-sorting *ngIf="sortInprogress.summer_dwt.isOpen"
                                        [isActive]="sortInprogress.summer_dwt.isActive"
                                        [data]="[{ 'name': 'Ascending', 'val': 'asc' }, { 'name': 'Descending', 'val': 'desc' }]"
                                        [sortType]="sortInprogress.summer_dwt.value" [colName]="'summer_dwt'"
                                        (sortingType)="fetchSortingType($event)"
                                        (popUpMouseOut)="popUpMouseLeaveClose($event)"></app-sorting>
                                </span>
                            </span>
                        </snl-th>                        
                        <snl-th>Year built</snl-th>
                        <snl-th>Compliance</snl-th>
                    </snl-row >
                    <snl-row *ngFor="let fl of fleetList| sort:sortingFieldType:sortingFieldName;"> 
                        <snl-td><span class="text__primary">{{fl.column5?(fl.column5 | titlecase):'-'}}</span></snl-td>
                        <snl-td><span class="text__primary">{{fl.port? (fl.port | titlecase) :'-'}}</span></snl-td> 
                        <snl-td>
                            <span>
                                <a *ngIf="fl.last_latitude != 0;else dash" class="text__semantic5 border-dash-b" href="javascript:void(0);" (click)="setLocation(fl)">
                                    View map
                                </a>
                                <ng-template #dash>-</ng-template>
                            </span>
                        </snl-td>
                        <snl-td class="text-end"><span class="text__primary">{{fl.summer_dwt ? (fl.summer_dwt | number:'1.0':'en-US'):'-'}}</span></snl-td>
                        <snl-td><span class="text__primary">{{fl.yr_built?(fl.yr_built):'-'}}</span></snl-td>
                        <snl-td>
                            <a class="text-decoration-none d-flex col_gap_8" rel=noopener target="_blank" href="{{'/Q88/'+ fl.imo_no}}">
                                <span class="text__semantic5 border-dash-b">View Q88</span> 
                                <svg-icon name="newtab-extlink"></svg-icon> 
                            </a>
                        </snl-td>
                    </snl-row>
                    <snl-row *ngIf="!fleetList.length">
                        <snl-td colspan="8" class="DataNotAvailable text-center mb-0"><span>No Records Found</span></snl-td>
                    </snl-row>
                </snl-table> 
            </ng-scrollbar>     
        </div> 

        <!-- Mobile Code Start Here -->
        <div class="MobileTableBoxHolder">
            <div class="MobileTableBox" *ngFor="let fl of fleetList;">
                 <div class="MobileTableBoxHead">
                    <h2 class="heading03 heading03-front">{{fl.column5?(fl.column5 | titlecase):'-'}}</h2>
                    <span>Next destination</span>
                    <p>{{fl.port? (fl.port|titlecase) :'-'}}</p> 
                    <div class="ContentIn">
                        <div class="LeftCnt"> 
                            <span>Year built</span>
                            <p>{{fl.yr_built?(fl.yr_built):'-'}}</p>
                        </div> 
                        <div class="RightCnt"> 
                            <span>Capacity (DWT)</span>
                            <p>{{fl.summer_dwt ? (fl.summer_dwt | number:'1.0':'en-US'):'-'}}</p>
                        </div>  
                    </div> 
                    <div class="FtrBtnSecMobile">
                        <span class="Icon-map"> <svg-icon name="Icon-map" *ngIf="fl.last_latitude != 0"></svg-icon><a *ngIf="fl.last_latitude != 0" class="links" (click)="setLocation(fl)" href="javascript:void(0);">View map</a></span>
                        <span class="Icon-download"> <svg-icon name="newtab-extlink"></svg-icon><a class="links small-IconSpce" target="_blank" href="{{'/Q88/'+ fl.imo_no}}" rel="noopener">View Q88</a></span>
                    </div> 
                 </div> 
            </div>   
        </div> 
    </div>   
    

</section> 



<!-- Table Section Stolt Skeleton Start -->
<section class="CommonContentSec TableFleetData" *ngIf="isSkelton">
    <div class="LargeWindowWidth">
        <div class="snl-table-holder M_CommonHidden"> 
            <snl-table class="snl-table-customized">
                <snl-row class="body-b1 fw-700 sticky-tbl-hdr"> 
                    <snl-th><span class="Stolt-Skeleton"></span></snl-th>
                    <snl-th><span class="Stolt-Skeleton"></span></snl-th>
                    <snl-th><span class="Stolt-Skeleton"></span></snl-th>
                    <snl-th><span class="Stolt-Skeleton"></span></snl-th>                       
                    <snl-th><span class="Stolt-Skeleton"></span></snl-th>
                    <snl-th><span class="Stolt-Skeleton"></span></snl-th>
                </snl-row> 
                <snl-row *ngFor="let number of [0,1,2,3,4,5,6,7,8,9]"> 
                    <snl-td><span class="Stolt-Skeleton"></span></snl-td>
                    <snl-td><span class="Stolt-Skeleton"></span></snl-td> 
                    <snl-td><span class="Stolt-Skeleton"></span></snl-td>
                    <snl-td><span class="Stolt-Skeleton"></span></snl-td>
                    <snl-td><span class="Stolt-Skeleton"></span></snl-td>
                    <snl-td><span class="Stolt-Skeleton"></span></snl-td>
                </snl-row> 
            </snl-table> 
        </div> 

        <!-- Mobile Data Skeleton Start -->
        <div class="SkeletonMob">
            <div class="MobileTableBoxHolder d-flex flex-column gap_16 w-100">
                <div class="MobileTableBox" *ngFor="let number of [0,1,2,3,4,6,7,8,9,10]">
                    <div class="MobileTableBoxHead BoxHead-Skeleton"> 
                        <div class="Stolt-Skeleton h-100"></div>
                    </div> 
                </div>    
            </div>
        </div> 

    </div>     
</section> 
 

<!-- Common Content Section -->
<section class="CommonContentSec GreyBG LetHelpYouSec">
    <div class="LargeWindowWidth">
        <div class="LetHelpYouSecInner">
            <img alt="stolt" class="BannerOverlaping" src="assets/img/public/stoltworker03.jpg" alt="Stolt workers image">
            <h3 class="heading03 heading03-front">Let us help you</h3>
            <p class="CommonPara">Still can’t find what you need? 
            <br>Leave your contact details for assistance.</p>
            <a class="BtnNoBgArrow"  [routerLink]="['/contact']">Contact us<img alt="login" src="assets/svg/login-arrow.svg" alt="login arrow"></a>   
        </div>
    </div>    
</section> 

<!-- Footer Section: This section represents the footer of the webpage -->
<app-footer></app-footer> 
<app-scroll-to-top></app-scroll-to-top> 


<ng-container *ngIf="sigleFleetMapPopUp">
<snl-modal (modalClose)="sigleFleetMapPopUpModel()" [type]="'large'" [open]="sigleFleetMapPopUp" class="CenterModelCmn MapmodelSnl">
     <div slot="heading">
        <h1 class="header-h1 m-0 pb-0">{{fleetItem?.column5 |titlecase}}</h1>
    </div>
    <div class="fleet__mdl" slot="modalContent">
        <app-agm-map [mapType]="'fleet'" [sItem]="fleetItem"></app-agm-map>
   </div>
</snl-modal>
</ng-container>
<app-show-announcement *ngIf="isPreview" [title]="title" [editorData]="editorData" [userEmail]="userEmail" [isPreview]="isPreview" (closePopupEmit)="isPreview = false"></app-show-announcement>
<!-- Temporarily disabling the NPS survey-->
<!-- <app-nps-survey-monkey *ngIf="showMonkeySurvey" [userId]="userId" (surveyClosed)="onSurveyClosed($event)"></app-nps-survey-monkey> -->
