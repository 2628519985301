
 <div class="StoltWrapper DarkHeaderMain"> 
    <!-- Header Section: This section represents the header of the webpage -->
    <app-about-header></app-about-header> 

    <!-- Common Page Title Section -->
    <div class="CommonHeroArea Common-bg-lines ContactTitleArea TitleWith-header">
        <div class="LargeWindowWidth">
            <div class="CommonHeroContent ">
                <h1 class="heading01 mb-0">Stolt-Nielsen Inland Tanker Service (SNITS)</h1>
            </div>
        </div>
    </div> 

    <main class="StoltMainContent"> 
        <!-- Common Content Section -->
        <section class="BannerMainSnits position-relative overflow-hidden">
            <div class="CommonContentSec">
                <div class="LargeWindowWidth">
                    <div class="HeadingHolder">
                        <h2 class="heading01">Stolt-Nielsen Inland Tanker Service (SNITS)</h2>
                    </div>  
                </div>
            </div>

            <div class="BannerHolderSnits"> 
                <div class="CommonContentSec">
                    <div class="LargeWindowWidth">
                        <div class="BannerContent">
                            <div class="BannerContent-in">
                                <h3 class="heading03-front fw-700"> 
                                    Experience Europe’s leading inland barge services
                                </h3>
                                <p class="m-0 font-16 fw-400">Stolt Tankers’ Premier Inland Tanker Services for efficient barge shipments (and temporary Floating Storage) from and to European ports in The Netherlands and Belgium over European rivers and canals including the majestic river Rhine and her adjacent rivers and canals.</p>
                                <div class="BtnGrp d-flex flex-column">
                                    <a class="BtnNoBgArrow position-relative m-0 fw-700" [routerLink]="['/fleetinfo/enquiryform']"> 
                                        Get a quote 
                                        <img src="assets/svg/login-arrow.svg" alt="login arrow">
                                    </a>
                                    <a class="BtnNoBgArrow position-relative m-0 fw-700" (click)="scroll(target)">
                                        Check out our fleet
                                        <img src="assets/svg/login-arrow.svg" alt="login arrow">
                                    </a>
                                </div>
                            </div>
                            <div class="BannerSec">
                                <img class="w-100 h-100" src="assets/img/public/snits-bnr.jpg" alt="Ship image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>         
        </section>


        <!-- Common Content Section Why Choose Us -->
        <section class="WhyChoseSec"> 
            <div class="CommonContentSec WhyChoseWraper">
                <div class="LargeWindowWidth">
                    <div class="WhyChoseIn"> 
                        <h2 class="heading03-front fw-700">Why Choose Us?</h2> 
                        <div class="ContentBxMain d-flex flex-column"> 
                            <div class="cardCntmn d-flex">

                                <!-- Common Card -->
                                <div class="ContentBX">
                                    <h3 class="heading03 font-24">
                                        <svg-icon src="/assets/svg/\public/tick-check.svg"></svg-icon>
                                        Unmatched experience
                                    </h3>
                                    <span class="font-16">With decades of experience in the maritime industry, Stolt Tankers has built a strong reputation as a trusted and reliable partner. We understand the unique challenges of navigating the inland waterways, and our dedicated team of professionals is well-versed in providing tailor-made solutions to meet your specific requirements.</span>
                                </div>

                                <!-- Common Card -->
                                <div class="ContentBX">
                                    <h3 class="heading03 font-24">
                                        <svg-icon src="/assets/svg/\public/tick-check.svg"></svg-icon>
                                        Extensive fleet
                                    </h3>
                                    <span class="font-16">
                                        Our extensive fleet of specialized barges is equipped with state-of-the-art technology, ensuring safe and efficient transportation of your valuable cargo. 
                                        Our vessels are designed to handle a wide range of Chemicals, Bio-components, and Mineral Oil Products, guaranteeing optimal delivery times and cost-effective operations.</span>
                                </div>
                            </div> 

                            <div class="cardCntmn d-flex border-0 pb-0">
                                <!-- Common Card -->
                                <div class="ContentBX">
                                    <h3 class="heading03 font-24">
                                        <svg-icon src="/assets/svg/\public/tick-check.svg"></svg-icon>
                                        Commitment to sustainability
                                    </h3>
                                    <span class="font-16">At Stolt Tankers, we are committed to minimizing our environmental footprint and promoting sustainable practices. Our fleet is designed to meet the highest industry standards for energy efficiency, reducing emissions and optimizing fuel consumption. We are investing in sustainability!</span>
                                </div>

                                <!-- Common Card -->
                                <div class="ContentBX">
                                    <h3 class="heading03 font-24">
                                        <svg-icon src="/assets/svg/\public/tick-check.svg"></svg-icon>
                                        Dedicated customer support
                                    </h3>
                                    <span class="font-16 d-flex">Our customer-centric approach sets us apart from the competition. We believe in building long-lasting relationships with our clients, and our dedicated team is glad to address your queries and provide timely assistance. Your satisfaction is our top priority.</span> 
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Common Content Section Our History -->
        <section class="HistorySec" [ngClass]="{'ShowFull':isShowMore}"> 
            <div class="CommonContentSec">
                <div class="LargeWindowWidth">
                    <h2 class="heading03-front fw-700 mb-2">Our history</h2>
                    <p class="m-0 SubHeadingSnt font-16">A brief timeline of SNITS history</p>
                    <div class="timeline">
                        <div class="outer">
                            <div class="card firstCard">
                                <div class="info">
                                <p class="title">1990</p>
                                <p class="m-0 SubHeadingSnt font-16">Stolt-Nielsen entering the market with 5 stainless steel new buildings</p>
                                </div>
                            </div>
                            
                            <div class="card">
                                <div class="info">
                                <p class="title">1994</p>
                                <p class="m-0 SubHeadingSnt font-16">purchased Petco (cargo broker)</p>
                                </div>
                            </div>
                         
                            <div class="card">
                                <div class="info">
                                <p class="title">1995</p>
                                <p class="m-0 SubHeadingSnt font-16">Integrated TTS fleet (3 stainless steel barges)</p>
                                </div>
                            </div>

                            <div class="ShowMoreHis">
                                <div class="card">
                                    <div class="info">
                                    <p class="title">1997</p>
                                    <p class="m-0 SubHeadingSnt font-16">Aquired "Hamburger Lloyd". Swiss based market leader inland chemical transports</p>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="info">
                                    <p class="title">2005</p>
                                    <p class="m-0 SubHeadingSnt font-16">Last "single hulled" vessel sold</p>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="info">
                                    <p class="title">2010</p>
                                    <p class="m-0 SubHeadingSnt font-16">Stolt Filia and Stolt Florence entered dedicated MEG transports</p>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="info">
                                    <p class="title">2012</p>
                                    <p class="m-0 SubHeadingSnt font-16">New build Stolt Rhine and Stolt Merwede joined </p>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="info">
                                    <p class="title">2018</p>
                                    <p class="m-0 SubHeadingSnt font-16">5 core barges re-engined with more sustainable CCR2 engines</p>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="info">
                                    <p class="title">2021</p>
                                    <p class="m-0 SubHeadingSnt font-16">Stolt Main sailing on HVO-20 reducing CO2 </p>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="info">
                                    <p class="title">2022</p>
                                    <p class="m-0 SubHeadingSnt font-16">Stolt Donau (5200DWT stainless steel) first "Stage V" engined chemical inland tanker & holding golden green award certificate</p>
                                    </div>
                                </div> 
                                
                                <div class="card">
                                    <div class="info">
                                    <p class="title">2023</p>
                                    <p class="m-0 SubHeadingSnt font-16">Stolt IJssel (5200DWT stainless steel) joined fleet. "Stage V" engine & holding golden green award certificate.</p>
                                    </div>
                                </div>

                                <div class="card lastCard">
                                    <div class="info">
                                    <p class="title">2023</p>
                                    <p class="m-0 SubHeadingSnt font-16">Stolt Ludwigshafen joined fleet. Ultra low water design and "Stage V" engine & holding golden green award certificate.</p>
                                    </div>
                                </div> 
                            </div> 
                        </div>  
                    </div>  

                    <!-- SHOW more less button -->
                    <div class="ShowmoreBtn">
                        <a class="BtnNoBgArrow position-relative m-0 fw-700">
                            <span class="MoreTxt" (click)="showMore(true)">Show more <img src="assets/svg/login-arrow.svg" alt="login arrow"></span>
                            <span class="LessTxt" (click)="showMore(false)">Show less <img src="assets/svg/login-arrow.svg" alt="login arrow"></span> 
                        </a>
                    </div>
                     
                </div>              
            </div>
        </section>        

       <!-- Our Global Flete Section -->
        <section class="CommonContentSec OurFleetSec position-relative overflow-hidden">
            <div class="LargeWindowWidth" #target>
                <div class="TableSnistData CommonTableWithMobileBx" >
                    <h2 class="heading03-front fw-700">Our regional fleet</h2>
                    <div class="snl-table-holder" *ngIf="isSkelton">
                        <ng-scrollbar
                        #scrollable
                        track="vertical" 
                        pointerEventsMethod="scrollbar"
                        [autoHeightDisabled]="false">
                            <snl-table class="snl-table-customized">
                                <snl-row class="body-b1 fw-700 sticky-tbl-hdr"> 
                                        <snl-th>
                                            <span class="TheadeContent">
                                                Vessel name 
                                                <span
                                                    [ngClass]="{'shortingIcons position-relative': true,
                                                    'active': sortInprogress.vsl_name.isActive,
                                                    'ascending-icon':sortInprogress.vsl_name.isActive && sortInprogress.vsl_name.value === 'asc','descending-icon':sortInprogress.vsl_name.isActive && sortInprogress.vsl_name.value === 'desc'}"
                                                    (click)="toogleSortingOrder('vsl_name')">
                                                    <svg-icon name="asc-dsc"></svg-icon>
                                                    <app-sorting *ngIf="sortInprogress.vsl_name.isOpen"
                                                        [isActive]="sortInprogress.vsl_name.isActive"
                                                        [data]="[{ 'name': 'Ascending', 'val': 'asc' }, { 'name': 'Descending', 'val': 'desc' }]"
                                                        [sortType]="sortInprogress.vsl_name.value" [colName]="'vsl_name'"
                                                        (sortingType)="fetchSortingType($event)"
                                                        (popUpMouseOut)="popUpMouseLeaveClose($event)"></app-sorting>
                                                </span>
                                            </span>
                                        </snl-th>
                                        <snl-th>
                                        <span class="TheadeContent">
                                                Capacity (DWT) 
                                                <span
                                                    [ngClass]="{'shortingIcons position-relative': true,
                                                    'active': sortInprogress.dwt.isActive,
                                                    'ascending-icon':sortInprogress.dwt.isActive && sortInprogress.dwt.value === 'asc','descending-icon':sortInprogress.dwt.isActive && sortInprogress.dwt.value === 'desc'}"
                                                    (click)="toogleSortingOrder('dwt')">
                                                    <svg-icon name="asc-dsc"></svg-icon>
                                                    <app-sorting *ngIf="sortInprogress.dwt.isOpen"
                                                        [isActive]="sortInprogress.dwt.isActive"
                                                        [data]="[{ 'name': 'Ascending', 'val': 'asc' }, { 'name': 'Descending', 'val': 'desc' }]"
                                                        [sortType]="sortInprogress.dwt.value"
                                                        [colName]="'dwt'"
                                                        (sortingType)="fetchSortingType($event)"
                                                        (popUpMouseOut)="popUpMouseLeaveClose($event)"></app-sorting>
                                                </span>
                                        </span>
                                        </snl-th>
                                        <snl-th>Cubic meter (CBM)</snl-th>
                                        <snl-th>Reg #</snl-th>
                                        <snl-th>Green award</snl-th>
                                </snl-row >  
                                <snl-row  *ngFor="let value of globalFleet | sort:sortingFieldType:sortingFieldName;">
                                    <snl-td><span class="text__primary">{{value.vsl_name?(value.vsl_name):'-'}}</span></snl-td>
                                    <snl-td><span class="text__primary CapacityRight">{{value.dwt? (value.dwt) :'-'}}</span></snl-td>
                                    <snl-td>
                                        <span class="text__primary CapacityRight">{{value.cbm ? (value.cbm | number:'1.0':'en-US'):'-'}}</span>
                                    </snl-td>
                                    <snl-td><span class="text__primary">{{value.reg}}</span></snl-td>
                                    <snl-td><span class="text__primary">{{value.green_award ? (value.green_award | titlecase) : '-'}}</span></snl-td>
                                </snl-row >
                                <snl-row  *ngIf="!globalFleet.length">
                                    <snl-td colspan="8" class="DataNotAvailable text-center mb-0">
                                        <span>No RecordsFound</span>
                                    </snl-td>
                                </snl-row> 
                            </snl-table>
                        </ng-scrollbar>
                    </div>

                    <!-- Mobile Code Start Here -->
                    <div class="MobileTableBoxHolder">
                        <div class="MobileTableBox"
                            *ngFor="let value of globalFleet | sort:sortingFieldType:sortingFieldName">
                            <div class="MobileTableBoxHead">
                                <h2 class="heading03 heading03-front">{{value.vsl_name?(value.vsl_name |
                                    titlecase):'-'}}</h2>
                                <div class="ContentIn">
                                    <div class="LeftCnt">
                                        <span>Capacity (DWT)</span>
                                        <p>{{value.dwt ? (value.dwt |
                                            number:'1.0':'en-US'):'-'}}</p>
                                    </div>
                                    <div class="RightCnt">
                                        <span>Cubic meter (CBM)</span>
                                        <p>{{value.cbm ? (value.cbm |
                                            number:'1.0':'en-US'):'-'}}</p>
                                    </div>
                                </div>

                                <div class="ContentIn pb-0">
                                    <div class="LeftCnt">
                                        <span>Reg #</span>
                                        <p>{{value.reg ? (value.reg):'-'}}</p>
                                    </div>
                                    <div class="RightCnt">
                                        <span>Green award</span>
                                        <p>{{value.green_award ? (value.green_award | titlecase) : '-'}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Mobile Code Start End -->

                </div>

                <!-- Table Section Stolt Skeleton Start -->
                <div class="TableSnistData stolt__scrollbar CommonTableWithMobileBx" *ngIf="!isSkelton">
                    <div class="LargeWindowWidth">
                        <div class="table-data CommonTableContainer">
                            <table class="table" aria-describedby="mydesc">
                                <thead class="thead-dark StickyThead">
                                    <tr>
                                        <th scope="col" class="align-middle"><span class="Stolt-Skeleton"></span>
                                        </th>
                                        <th scope="col" class="align-middle"><span class="Stolt-Skeleton"></span>
                                        </th>
                                        <th scope="col" class="align-middle"><span class="Stolt-Skeleton"></span>
                                        </th>
                                        <th class="text_right align-middle" scope="col"><span
                                                class="Stolt-Skeleton"></span>
                                        </th>
                                        <th scope="col" class="align-middle"><span class="Stolt-Skeleton"></span>
                                        </th>
                                        <th scope="col" class="align-middle"><span class="Stolt-Skeleton"></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let number of [0,1,2,3,4,5,6,7,8,9]">
                                        <td><span class="Stolt-Skeleton"></span></td>
                                        <td><span class="Stolt-Skeleton"></span></td>
                                        <td><span class="Stolt-Skeleton"></span></td>
                                        <td class="text_right"><span class="Stolt-Skeleton"></span></td>
                                        <td><span class="Stolt-Skeleton"></span></td>
                                        <td><span class="Stolt-Skeleton"></span></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                        <!-- Mobile Data Skeleton Start -->
                        <div class="MobileTableBoxHolder" *ngFor="let number of [0,1,2,3,4,6,7,8,9]">
                            <div class="MobileTableBox">
                                <div class="MobileTableBoxHead BoxHead-Skeleton">
                                    <div class="Stolt-Skeleton h-100"></div>
                                </div>
                            </div>
                        </div>
                        <!-- Mobile Data Skeleton End -->

                    </div>
                </div>
                <!-- Table Section Stolt Skeleton End --> 
            </div>    
        </section>
    </main>

    <!-- Footer Section: This section represents the footer of the webpage -->
    <app-footer></app-footer>
    <app-scroll-to-top></app-scroll-to-top> 

</div>


<!-- Modal Start Here -->
<div class="StickyShareBox fleetMapHd" *ngIf="sigleFleetMapPopUp">
    <div class="CustomModelContent modelfleetmap">
        <div class="StickyShareBoxInner">
            <div class="ShareHeadTitle">
                <p class="titleFletMp">{{snitsFleetItem?.vsl_name}}
                    <span class="btn-close" (click)="sigleFleetMapPopUp=false"></span>
                </p>
            </div>
            <div class="mapbdy mapbdyup">
                <!-- Map section -->
                <div><app-agm-map [mapType]="'snits'" [snitsItem]="snitsFleetItem"></app-agm-map></div>
                <!-- Map section end -->
            </div>
        </div>
    </div>
</div>
<app-show-announcement *ngIf="isPreview" [title]="title" [editorData]="editorData" [userEmail]="userEmail" [isPreview]="isPreview" (closePopupEmit)="isPreview = false"></app-show-announcement>
<!-- Temporarily disabling the NPS survey-->
<!-- <app-nps-survey-monkey *ngIf="showMonkeySurvey" [userId]="userId" (surveyClosed)="onSurveyClosed($event)"></app-nps-survey-monkey> -->
