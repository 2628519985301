import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-track-priorport',
  templateUrl: './track-priorport.component.html',
  styleUrls: ['./track-priorport.component.scss']
})
export class TrackPriorportComponent {
   @Input() bookingDetailData:any;
   @Input() isTransshipment:boolean;
   @Input() delayObj:any
   @Input() bookingVariance:any
   @Output() mobileModelSource = new EventEmitter()
   @Input() trackPageData:any
  isShowPriorItinerary:boolean = true;

  ShowPriorItinerary(){
    this.isShowPriorItinerary = !this.isShowPriorItinerary;
 }

 tbdTooltip(event:any)
 {
  this.mobileModelSource.emit(event)
 }

 delay(val: any) {
  return Math.round(val);
}
}
